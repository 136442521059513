const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['close-nav'] = require('../middleware/close-nav.js')
middleware['close-nav'] = middleware['close-nav'].default || middleware['close-nav']

middleware['survey'] = require('../middleware/survey.js')
middleware['survey'] = middleware['survey'].default || middleware['survey']

export default middleware
