/*
  2WAV INC. PROVIDES THIS SOFTWARE TO YOU AS IS AND WITHOUT WARRANTY OF ANY KIND, EXPRESS, IMPLIED OR OTHERWISE, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.

  © 2021 2wav inc. All rights reserved, except as specifically provided by Agreement.
*/

import Vue from "vue";
import consola from "consola";
import _flatten from "lodash/flatten";
import _difference from "lodash/difference";
import QuestionValueFactory from "~/lib/QuestionValueFactory";

export const state = () => ({
  formData: {
    // Question1: { <- Unanswered question
    //   "questionId":"Question1",
    //   "answer": "Single location",
    //   "createdAt": "Thu Dec 17 2020 16:38:10 GMT-0600 (Central Standard Time)",
    //   "timezoneOffset": -360
    // }
    // Question1: { <- Answered question
    //   "questionId": "Question1",
    //   "answer": "More than one location",
    //   "createdAt": "Thu Dec 17 2020 16:39:40 GMT-0600 (Central Standard Time)",
    //   "timezoneOffset": -360,
    //   "optionValue": 1
    // }
  },
  lastSurvey: {},
  hasSurvey: false,
  terminated: null,
  terminatedResponse: null,
  dividers: [
    "Question3",
    "Question4c",
    "Question6",
    "Question8",
    "Question9b",
    "Question12a",
    "Question13",
    "Question19",
    "Question21",
    "Question27",
  ],
  dialogCancel: "Cancel",
  dialogConfirm: "Confirm",
  dynamicDialogComponentValue: null,
  showDynamicComponent: false,
  questionDialogVisible: false,
  questionDialogTitle: null,
  questionInformation: null,
  questionDialogConfirm() {},
  done: false,
  complete: false,
  recoveryStatus: null,
  messages: {},
  questionScratch: {}, // This is where generated values used in questions will be stored
  questionCounts: {
    Question12a: 0,
    Question14a: 0,
    Question16: 0,
    Question18: 0,
    Question26: 0,
  },
  calculations: [],
  resultUrls: [],
  calculatorUrls: [],
  questions: [
    {
      _id: "Question1",
      num: 1,
      text: "How many locations does your affected business have?",
      dependency: "start",
      afterInput: "Question1",
      optionsType: "select",
      options: [
        { value: "Single location", label: "Single location" },
        {
          value: "More than one location",
          label: "More than one location",
        },
      ],
    },
    {
      _id: "Question1a",
      num: 2,
      description:
        "Important note: Please answer all remaining questions for the location/franchise that was affected most, and not for the entirety of your multi-location business.",
      text: "How many?",
      dependency: "isQuestion1aReady",
      optionsType: "number",
      minNumber: 2,
    },
    {
      _id: "Question2",
      num: 3,
      text: "In what state is your affected business located?",
      dependency: "isQuestion2Ready",
      afterInput: "Question2",
      optionsType: "select",
      options: [
        { value: "AL", label: "Alabama" },
        { value: "AK", label: "Alaska" },
        { value: "AZ", label: "Arizona" },
        { value: "AR", label: "Arkansas" },
        { value: "CA", label: "California" },
        { value: "CO", label: "Colorado" },
        { value: "CT", label: "Connecticut" },
        { value: "DE", label: "Delaware" },
        { value: "FL", label: "Florida" },
        { value: "GA", label: "Georgia" },
        { value: "HI", label: "Hawaii" },
        { value: "ID", label: "Idaho" },
        { value: "IL", label: "Illinois" },
        { value: "IN", label: "Indiana" },
        { value: "IA", label: "Iowa" },
        { value: "KS", label: "Kansas" },
        { value: "KY", label: "Kentucky" },
        { value: "LA", label: "Louisiana" },
        { value: "ME", label: "Maine" },
        { value: "MD", label: "Maryland" },
        { value: "MA", label: "Massachusetts" },
        { value: "MI", label: "Michigan" },
        { value: "MN", label: "Minnesota" },
        { value: "MO", label: "Missouri" },
        { value: "MT", label: "Montana" },
        { value: "NE", label: "Nebraska" },
        { value: "NV", label: "Nevada" },
        { value: "NH", label: "New Hampshire" },
        { value: "NJ", label: "New Jersey" },
        { value: "NM", label: "New Mexico" },
        { value: "NY", label: "New York" },
        { value: "NC", label: "North Carolina" },
        { value: "ND", label: "North Dakota" },
        { value: "OH", label: "Ohio" },
        { value: "OK", label: "Oklahoma" },
        { value: "OR", label: "Oregon" },
        { value: "PA", label: "Pennsylvania" },
        { value: "RI", label: "Rhode Island" },
        { value: "SC", label: "South Carolina" },
        { value: "SD", label: "South Dakota" },
        { value: "TN", label: "Tennessee" },
        { value: "TX", label: "Texas" },
        { value: "UT", label: "Utah" },
        { value: "VT", label: "Vermont" },
        { value: "VA", label: "Virginia" },
        { value: "WA", label: "Washington" },
        { value: "WV", label: "West Virginia" },
        { value: "WI", label: "Wisconsin" },
        { value: "WY", label: "Wyoming" },
      ],
    },

    {
      _id: "Question3",
      num: 4,
      text: "In what zip code is your affected business located?",
      dependency: "isQuestion3Ready",
      afterInput: "Question3",
      optionsType: "string",
    },

    {
      _id: "Question4",
      num: 5,
      description:
        "Tell us a bit about the disaster that affected your business.",
      text: "What type of disaster was it?",
      dependency: "isQuestion4Ready",
      optionsType: "select",
      options: [
        { value: "pandemic", label: "pandemic" },
        { value: "hurricane", label: "hurricane" },
        { value: "earthquake", label: "earthquake" },
        { value: "power grid failure", label: "power grid failure" },
        { value: "fire", label: "fire" },
        { value: "flood", label: "flood" },
        { value: "tornado", label: "tornado" },
        {
          value: "other",
          label: "other",
          questions: [
            {
              _id: "Question4a",
              num: 6,
              text: "Your business experienced a disaster other than the ones presented in the list. Can you specify the type of disaster that your business experienced?",
              dependency: "isQuestion4aReady",
              optionsType: "string",
            },
          ],
        },
      ],
    },

    {
      _id: "Question4b",
      num: 7,
      text: "Please provide the disaster name (e.g., Hurricane Ike). If the disaster does not have a common name, please provide a text description of the disaster (e.g., a severe snowstorm).",
      dependency: "isQuestion4bReady",
      optionsType: "string",
    },

    {
      _id: "Question4c",
      num: 8,
      text: "Please provide the initial date the disaster occurred.",
      dependency: "isQuestion4cReady",
      optionsType: "month",
      renderOptions: "previousTenYears",
    },

    {
      _id: "Question5",
      num: 9,
      text: "Which of the following best describes your level of responsibility for financial decisions in your company? Please select one answer",
      dependency: "#Question4c",
      afterInput: "Question5",
      optionsType: "select",
      options: [
        {
          value:
            "You are solely responsible for making the financial decisions",
          label:
            "You are solely responsible for making the financial decisions",
        },
        {
          value: "You share responsibility for making the financial decisions",
          label: "You share responsibility for making the financial decisions",
        },
        {
          value:
            "You are actively involved in or aware of the financial decisions for your firm",
          label:
            "You are actively involved in or aware of the financial decisions for your firm",
        },
        {
          value:
            "You are not actively involved in making the financial decisions, but your opinion is sought",
          label:
            "You are not actively involved in making the financial decisions, but your opinion is sought",
        },
        {
          value: "Someone else makes most financial decisions",
          label: "Someone else makes most financial decisions",
        },
        {
          value: "Someone else makes all financial decisions",
          label: "Someone else makes all financial decisions",
        },
      ],
    },

    {
      _id: "Question6",
      num: 10,
      text: "Were you working at the same affected business you are working at now when this disaster occurred?",
      // dependency: "#Question5",
      dependency: "isQuestion6Ready",
      optionsType: "select",
      options: [
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
      ],
    },

    {
      _id: "Question7",
      num: 11,
      description:
        "Business Interruption is a reduction in the production of goods and services due to a disaster. Business Interruption is measured as a reduction in Sales Revenue.",
      text: "Following the disaster, did your business experience Business Interruption?",
      // dependency: "#Question6",
      dependency: "isQuestion7Ready",
      optionsType: "select",
      options: [
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
      ],
    },

    {
      _id: "Question8",
      num: 12,
      text: "Since the disaster, to what extent has your affected business recovered the ability to produce pre-disaster levels of goods and services, <em>regardless of customer demand</em>&hairsp;?",
      // dependency: "#Question7",
      dependency: "isQuestion8Ready",
      afterInput: "Question8",
      optionsType: "select",
      options: [
        { value: "Not recovered at all", label: "Not recovered at all" },
        {
          value: "Recovered somewhat, but not fully",
          label: "Recovered somewhat, but not fully",
        },
        {
          value: "Recovered to the SAME LEVELS as before the disaster",
          label: "Recovered to the SAME LEVELS as before the disaster",
        },
        {
          value: "Recovered to levels BETTER THAN before the disaster",
          label: "Recovered to levels BETTER THAN before the disaster",
        },
      ],
    },

    {
      _id: "Question9",
      num: 13,
      description:
        "Please provide your best estimate of the number of employees (in terms of full-time equivalents—FTEs) your affected business had, on average. FTEs are a standardized measure of employees. For example, two half-time (20 hour/week) employees should be reported as 1 FTE.",
      // dependency: "#Question8",
      text: " ",
      dependency: "isQuestion9Ready",
      optionsType: "placeholder",
      options: [
        {
          value: "",
          label: "",
          questions: [
            {
              _id: "Question9a",
              num: 14,
              text: "PRIOR to the disaster",
              dependency: "isQuestion8Ready",
              optionsType: "number",
              renderOptions: "float",
            },
            {
              _id: "Question9b",
              num: 15,
              text: "During RECOVERY from the disaster",
              dependency: "isQuestion9bReady",
              optionsType: "number",
              renderOptions: "float",
            },
          ],
        },
      ],
    },

    // {
    //   _id: "Question10",
    //   num: 16,
    //   description:
    //     "The following questions ask about Property Damage to your business as a result of the disaster. Property Damage refers to BOTH structural damage (e.g., roof or wall collapses) and non-structural damage (e.g., broken windows, fixtures, damage to equipment, furnishings, inventory/stock, etc.).",
    //   text: " ",
    //   dependency: "isQuestion10Ready",
    //   afterInput: "Question10",
    //   optionsType: "placeholder",
    //   options: [
    //     {
    //       value: "",
    //       label: "",
    //       // questions: [
    //       //   {
    //       //     _id: "Question10a",
    //       //     num: 17,
    //       //     text:
    //       //       "Did your affected business have property damaged by the disaster?",
    //       //     dependency: "isQuestion9Ready",
    //       //     optionsType: "select",
    //       //     options: [
    //       //       { value: "yes", label: "Yes" },
    //       //       { value: "no", label: "No" },
    //       //     ],
    //       //   },
    //       // ],
    //     },
    //   ],
    // },

    {
      _id: "Question10a",
      num: 17,
      description:
        "The following questions ask about Property Damage to your business as a result of the disaster. Property Damage refers to BOTH structural damage (e.g., roof or wall collapses) and non-structural damage (e.g., broken windows, fixtures, damage to equipment, furnishings, inventory/stock, etc.).",
      text: "Did your affected business have property damaged by the disaster?",
      dependency: "isQuestion10Ready",
      optionsType: "select",
      options: [
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
      ],
    },

    {
      _id: "Question11",
      num: 18,
      text: "What is your best estimate of the Property Damage to your affected business from the disaster? Note, if the disaster was more than a few years ago, do your best to provide the property damage estimate in dollars from that time period (i.e., nominal rather than inflation-adjusted dollars). For example, if the disaster affected your business eight years ago, do not report values adjusted to today's value of the dollar.",
      dependency: "isQuestion10ReadyYes",
      optionsType: "number",
      renderOptions: "medium",
      minNumber: 1,
    },

    {
      _id: "Question12",
      num: 19,
      text: "What date do you consider your affected business recovered the ability to produce pre-disaster levels of goods and services, regardless of customer demand? If you consider that your business recovery occurred the same month and year of the experienced disaster, select it below, otherwise, please provide your best estimate of month and year of recovery.",
      dependency: "isQuestion12Ready",
      afterInput: "Question12",
      optionsType: "month",
      renderOptions: "afterQuestion4c",
      options: [
        {
          value: "",
          label: "",
          // questions: [
          //   {
          //     _id: "Question12a",
          //     num: 20,
          //     text:
          //       "According to your selection, you are indicating that your business recovered in length_of_disaster month(s). Do you agree?",
          //     dependency: "isQuestion12aReady",
          //     calculatedValues: [
          //       {
          //         name: "length_of_disaster",
          //         operation: "-",
          //         optionsType: "month",
          //         value1: "Question12",
          //         value2: "Question4c",
          //         altTextValue: 0,
          //         altText:
          //           "According to your selection, you are indicating that your business recovered in LESS THAN A FULL month. Do you agree?",
          //       },
          //     ],
          //     optionsType: "select",
          //     options: [
          //       { value: "yes", label: "Yes" },
          //       { value: "no", label: "No" },
          //     ],
          //     // afterInput: "isQuestion12Done",
          //     afterInput: "Question12a",
          //   },
          // ],
        },
      ],
    },

    {
      _id: "Question12a",
      num: 20,
      text: "According to your selection, you are indicating that your business recovered in length_of_disaster month(s). Do you agree?",
      dependency: "isQuestion12aReady",
      calculatedValues: [
        {
          name: "length_of_disaster",
          operation: "-",
          optionsType: "month",
          value1: "Question12",
          value2: "Question4c",
          altTextValue: 0,
          altText:
            "According to your selection, you are indicating that your business recovered in LESS THAN A FULL month. Do you agree?",
        },
      ],
      optionsType: "select",
      options: [
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
      ],
      // afterInput: "isQuestion12Done",
      afterInput: "Question12a",
    },

    {
      _id: "Question13X",
      num: 21,
      text: "Business Interruption is a reduction in the production of goods and services due to a disaster. Business Interruption is measured as a reduction in Sales Revenue. What percent of your business has recovered the ability or capacity to produce pre-disaster levels of goods and services, regardless of customer demand?",
      optionsType: "slider",
      renderOptions: "slider1to99",
      dependency: "isQuestion13XReady",
      // afterInput: "lessThan25PercentMessage",
      afterInput: "Question13X",
    },

    {
      _id: "Question13",
      num: 22,
      // description:
      //   "Business Interruption is a reduction in the production of goods and
      //   services due to a disaster. Business Interruption is measured as a
      //   reduction in Sales Revenue.",
      description:
        "Using a scale from 0-100, please indicate what percent each of the listed problems contributed to your Business Interruption. The total of your responses should equal 100%.",
      text: " ",
      dependency: "isQuestion13Ready",
      optionsType: "questiongroup",
      optionsModifier: "random",
      options: [
        {
          _id: "Question13a",
          num: 23,
          text: "Employees unable to travel to work",
          optionsType: "slider",
          required: false,
          renderOptions: "defaultSliderOptions",
          dependency: "isQuestion13Ready",
          afterInput: "Question13",
        },
        {
          _id: "Question13b",
          num: 24,
          text: "Employees moved away",
          optionsType: "slider",
          required: false,
          renderOptions: "defaultSliderOptions",
          dependency: "isQuestion13Ready",
          afterInput: "Question13",
        },
        {
          _id: "Question13c",
          num: 25,
          text: "Communication problems (e.g., cell phones or Internet not working)",
          optionsType: "slider",
          required: false,
          renderOptions: "defaultSliderOptions",
          dependency: "isQuestion13Ready",
          afterInput: "Question13",
        },
        {
          _id: "Question13d",
          num: 26,
          text: "Supply chain problems (e.g., could not obtain the needed material inputs)",
          optionsType: "slider",
          required: false,
          renderOptions: "defaultSliderOptions",
          dependency: "isQuestion13Ready",
          afterInput: "Question13",
        },
        {
          _id: "Question13e",
          num: 27,
          text: "Power disruptions",
          required: false,
          optionsType: "slider",
          renderOptions: "defaultSliderOptions",
          dependency: "isQuestion13Ready",
          afterInput: "Question13",
        },
        {
          _id: "Question13f",
          num: 28,
          text: "Natural gas disruptions",
          optionsType: "slider",
          required: false,
          renderOptions: "defaultSliderOptions",
          dependency: "isQuestion13Ready",
          afterInput: "Question13",
        },
        {
          _id: "Question13g",
          num: 29,
          text: "Water disruptions",
          optionsType: "slider",
          required: false,
          renderOptions: "defaultSliderOptions",
          dependency: "isQuestion13Ready",
          afterInput: "Question13",
        },
        {
          _id: "Question13h",
          num: 30,
          text: "Transportation problems",
          optionsType: "slider",
          required: false,
          renderOptions: "defaultSliderOptions",
          dependency: "isQuestion13Ready",
          afterInput: "Question13",
        },
        // {
        //   _id: "Question13i",
        //   num: 31,
        //   text: "Facility damage (i.e., damage to physical plant)",
        //   optionsType: "slider",
        //   required: false,
        //   renderOptions: "defaultSliderOptions",
        //   dependency: "isQuestion13Ready",
        //   // afterInput: "Question13",
        // },
        // {
        //   _id: "Question13j",
        //   num: 32,
        //   text: "Equipment/Machinery Damage",
        //   optionsType: "slider",
        //   required: false,
        //   renderOptions: "defaultSliderOptions",
        //   dependency: "isQuestion13Ready",
        //   // afterInput: "Question13",
        // },
        {
          _id: "Question13k",
          num: 33,
          text: "Government decree (e.g., mandatory business closure; quarantine; facility red-tagged)",
          optionsType: "slider",
          required: false,
          renderOptions: "defaultSliderOptions",
          dependency: "isQuestion13Ready",
          afterInput: "Question13",
        },
      ],
    },

    {
      _id: "Question14",
      num: 34,
      text: "Between start and end_recoveryText_, what is your best estimate of your affected business's expected sales revenue (in dollars) <strong>had there been NO disaster</strong>?",
      dependency: "isQuestion14Ready",
      afterInput: "Question14",
      optionsType: "select",
      options: [
        {
          value: "Less than $5,000",
          label: "Less than $5,000",
          questions: [
            {
              _id: "Question14a",
              num: 35,
              text: "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
              dependency: "isQuestion14aReady",
              optionsType: "select",
              // afterInput: "writeRangeValues",
              afterInput: "Question14a",
              options: [
                {
                  value: "$500 to less than $1,000",
                  label: "$500 to less than $1,000",
                  reportedValue: 750,
                },
                {
                  value: "$1,000 to less than $1,500",
                  label: "$1,000 to less than $1,500",
                  reportedValue: 1250,
                },
                {
                  value: "$1,500 to less than $2,000",
                  label: "$1,500 to less than $2,000",
                  reportedValue: 1750,
                },
                {
                  value: "$2,000 to less than $2,500",
                  label: "$2,000 to less than $2,500",
                  reportedValue: 2250,
                },
                {
                  value: "$2,500 to less than $3,000",
                  label: "$2,500 to less than $3,000",
                  reportedValue: 2750,
                },
                {
                  value: "$3,000 to less than $3,500",
                  label: "$3,000 to less than $3,500",
                  reportedValue: 3250,
                },
                {
                  value: "$3,500 to less than $4,000",
                  label: "$3,500 to less than $4,000",
                  reportedValue: 3750,
                },
                {
                  value: "$4,000 to less than $4,500",
                  label: "$4,000 to less than $4,500",
                  reportedValue: 4250,
                },
                {
                  value: "$4,500 to less than $5,000",
                  label: "$4,500 to less than $5,000",
                  reportedValue: 4750,
                },
              ],
            },
          ],
        },
        {
          value: "$5,000 to less than $10,000",
          label: "$5,000 to less than $10,000",
          questions: [
            {
              _id: "Question14a",
              num: 35,
              text: "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
              dependency: "isQuestion14aReady",
              optionsType: "select",
              // afterInput: "writeRangeValues",
              afterInput: "Question14a",
              options: [
                {
                  value: "$5,000 to less than $5,500",
                  label: "$5,000 to less than $5,500",
                  reportedValue: 5250,
                },
                {
                  value: "$5,500 to less than $6,000",
                  label: "$5,500 to less than $6,000",
                  reportedValue: 5750,
                },
                {
                  value: "$6,000 to less than $6,500",
                  label: "$6,000 to less than $6,500",
                  reportedValue: 6250,
                },
                {
                  value: "$6,500 to less than $7,000",
                  label: "$6,500 to less than $7,000",
                  reportedValue: 6750,
                },
                {
                  value: "$7,000 to less than $7,500",
                  label: "$7,000 to less than $7,500",
                  reportedValue: 7250,
                },
                {
                  value: "$7,500 to less than $8,000",
                  label: "$7,500 to less than $8,000",
                  reportedValue: 7750,
                },
                {
                  value: "$8,000 to less than $8,500",
                  label: "$8,000 to less than $8,500",
                  reportedValue: 8250,
                },
                {
                  value: "$8,500 to less than $9,000",
                  label: "$8,500 to less than $9,000",
                  reportedValue: 8750,
                },
                {
                  value: "$9,000 to less than $9,500",
                  label: "$9,000 to less than $9,500",
                  reportedValue: 8250,
                },
                {
                  value: "$9,500 to less than $10,000",
                  label: "$9,500 to less than $10,000",
                  reportedValue: 9750,
                },
              ],
            },
          ],
        },
        {
          value: "$10,000 to less than $25,000",
          label: "$10,000 to less than $25,000",
          questions: [
            {
              _id: "Question14a",
              num: 35,
              text: "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
              dependency: "isQuestion14aReady",
              optionsType: "select",
              // afterInput: "writeRangeValues",
              afterInput: "Question14a",
              options: [
                {
                  value: "$10,000 to less than $11,000",
                  label: "$10,000 to less than $11,000",
                  reportedValue: 10500,
                },
                {
                  value: "$11,000 to less than $12,000",
                  label: "$11,000 to less than $12,000",
                  reportedValue: 11500,
                },
                {
                  value: "$12,000 to less than $13,000",
                  label: "$12,000 to less than $13,000",
                  reportedValue: 12500,
                },
                {
                  value: "$13,000 to less than $14,000",
                  label: "$13,000 to less than $14,000",
                  reportedValue: 13500,
                },
                {
                  value: "$14,000 to less than $15,000",
                  label: "$14,000 to less than $15,000",
                  reportedValue: 14500,
                },
                {
                  value: "$15,000 to less than $16,000",
                  label: "$15,000 to less than $16,000",
                  reportedValue: 15500,
                },
                {
                  value: "$16,000 to less than $17,000",
                  label: "$16,000 to less than $17,000",
                  reportedValue: 16500,
                },
                {
                  value: "$17,000 to less than $18,000",
                  label: "$17,000 to less than $18,000",
                  reportedValue: 17500,
                },
                {
                  value: "$18,000 to less than $19,000",
                  label: "$18,000 to less than $19,000",
                  reportedValue: 18500,
                },
                {
                  value: "$19,000 to less than $20,000",
                  label: "$19,000 to less than $20,000",
                  reportedValue: 19500,
                },
                {
                  value: "$20,000 to less than $21,000",
                  label: "$20,000 to less than $21,000",
                  reportedValue: 20500,
                },
                {
                  value: "$21,000 to less than $22,000",
                  label: "$21,000 to less than $22,000",
                  reportedValue: 21500,
                },
                {
                  value: "$22,000 to less than $23,000",
                  label: "$22,000 to less than $23,000",
                  reportedValue: 22500,
                },
                {
                  value: "$23,000 to less than $24,000",
                  label: "$23,000 to less than $24,000",
                  reportedValue: 23500,
                },
                {
                  value: "$24,000 to less than $25,000",
                  label: "$24,000 to less than $25,000",
                  reportedValue: 24500,
                },
              ],
            },
          ],
        },
        {
          value: "$25,000 to less than $50,000",
          label: "$25,000 to less than $50,000",
          questions: [
            {
              _id: "Question14a",
              num: 35,
              text: "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
              dependency: "isQuestion14aReady",
              optionsType: "select",
              // afterInput: "writeRangeValues",
              afterInput: "Question14a",
              options: [
                {
                  value: "$25,000 to less than $27,500",
                  label: "$25,000 to less than $27,500",
                  reportedValue: 26250,
                },
                {
                  value: "$27,500 to less than $30,000",
                  label: "$27,500 to less than $30,000",
                  reportedValue: 28750,
                },
                {
                  value: "$30,000 to less than $32,500",
                  label: "$30,000 to less than $32,500",
                  reportedValue: 31250,
                },
                {
                  value: "$32,500 to less than $35,000",
                  label: "$32,500 to less than $35,000",
                  reportedValue: 33750,
                },
                {
                  value: "$35,000 to less than $37,500",
                  label: "$35,000 to less than $37,500",
                  reportedValue: 36250,
                },
                {
                  value: "$37,500 to less than $40,000",
                  label: "$37,500 to less than $40,000",
                  reportedValue: 38750,
                },
                {
                  value: "$40,000 to less than $42,500",
                  label: "$40,000 to less than $42,500",
                  reportedValue: 41250,
                },
                {
                  value: "$42,500 to less than $45,000",
                  label: "$42,500 to less than $45,000",
                  reportedValue: 43750,
                },
                {
                  value: "$45,000 to less than $47,500",
                  label: "$45,000 to less than $47,500",
                  reportedValue: 46250,
                },
                {
                  value: "$47,500 to less than $50,000",
                  label: "$47,500 to less than $50,000",
                  reportedValue: 48750,
                },
              ],
            },
          ],
        },
        {
          value: "$50,000 to less than $75,000",
          label: "$50,000 to less than $75,000",
          questions: [
            {
              _id: "Question14a",
              num: 35,
              text: "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
              dependency: "isQuestion14aReady",
              optionsType: "select",
              // afterInput: "writeRangeValues",
              afterInput: "Question14a",
              options: [
                {
                  value: "$50,000 to less than $52,500",
                  label: "$50,000 to less than $52,500",
                  reportedValue: 51250,
                },
                {
                  value: "$52,500 to less than $55,000",
                  label: "$52,500 to less than $55,000",
                  reportedValue: 53750,
                },
                {
                  value: "$55,000 to less than $57,500",
                  label: "$55,000 to less than $57,500",
                  reportedValue: 56250,
                },
                {
                  value: "$57,500 to less than $60,000",
                  label: "$57,500 to less than $60,000",
                  reportedValue: 58750,
                },
                {
                  value: "$60,000 to less than $62,500",
                  label: "$60,000 to less than $62,500",
                  reportedValue: 61250,
                },
                {
                  value: "$62,500 to less than $65,000",
                  label: "$62,500 to less than $65,000",
                  reportedValue: 63750,
                },
                {
                  value: "$65,000 to less than $67,500",
                  label: "$65,000 to less than $67,500",
                  reportedValue: 66250,
                },
                {
                  value: "$67,500 to less than $70,000",
                  label: "$67,500 to less than $70,000",
                  reportedValue: 68750,
                },
                {
                  value: "$70,000 to less than $72,500",
                  label: "$70,000 to less than $72,500",
                  reportedValue: 71250,
                },
                {
                  value: "$72,500 to less than $75,000",
                  label: "$72,500 to less than $75,000",
                  reportedValue: 73750,
                },
              ],
            },
          ],
        },
        {
          value: "$75,000 to less than $100,000",
          label: "$75,000 to less than $100,000",
          questions: [
            {
              _id: "Question14a",
              num: 35,
              text: "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
              dependency: "isQuestion14aReady",
              optionsType: "select",
              // afterInput: "writeRangeValues",
              afterInput: "Question14a",
              options: [
                {
                  value: "$75,000 to less than $77,500",
                  label: "$75,000 to less than $77,500",
                  reportedValue: 75250,
                },
                {
                  value: "$77,500 to less than $80,000",
                  label: "$77,500 to less than $80,000",
                  reportedValue: 78750,
                },
                {
                  value: "$80,000 to less than $82,500",
                  label: "$80,000 to less than $82,500",
                  reportedValue: 81250,
                },
                {
                  value: "$82,500 to less than $85,000",
                  label: "$82,500 to less than $85,000",
                  reportedValue: 83750,
                },
                {
                  value: "$85,000 to less than $87,500",
                  label: "$85,000 to less than $87,500",
                  reportedValue: 86250,
                },
                {
                  value: "$87,500 to less than $90,000",
                  label: "$87,500 to less than $90,000",
                  reportedValue: 88750,
                },
                {
                  value: "$90,000 to less than $92,500",
                  label: "$90,000 to less than $92,500",
                  reportedValue: 91250,
                },
                {
                  value: "$92,500 to less than $95,000",
                  label: "$92,500 to less than $95,000",
                  reportedValue: 93750,
                },
                {
                  value: "$95,000 to less than $97,500",
                  label: "$95,000 to less than $97,500",
                  reportedValue: 96250,
                },
                {
                  value: "$97,500 to less than $100,000",
                  label: "$97,500 to less than $100,000",
                  reportedValue: 98750,
                },
              ],
            },
          ],
        },
        {
          value: "$100,000 to less than $200,000",
          label: "$100,000 to less than $200,000",
          questions: [
            {
              _id: "Question14a",
              num: 35,
              text: "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
              dependency: "isQuestion14aReady",
              optionsType: "select",
              // afterInput: "writeRangeValues",
              afterInput: "Question14a",
              options: [
                {
                  value: "$100,000 to less than $110,000",
                  label: "$100,000 to less than $110,000",
                  reportedValue: 105000,
                },
                {
                  value: "$110,000 to less than $120,000",
                  label: "$110,000 to less than $120,000",
                  reportedValue: 115000,
                },
                {
                  value: "$120,000 to less than $130,000",
                  label: "$120,000 to less than $130,000",
                  reportedValue: 125000,
                },
                {
                  value: "$130,000 to less than $140,000",
                  label: "$130,000 to less than $140,000",
                  reportedValue: 135000,
                },
                {
                  value: "$140,000 to less than $150,000",
                  label: "$140,000 to less than $150,000",
                  reportedValue: 145000,
                },
                {
                  value: "$150,000 to less than $160,000",
                  label: "$150,000 to less than $160,000",
                  reportedValue: 155000,
                },
                {
                  value: "$160,000 to less than $170,000",
                  label: "$160,000 to less than $170,000",
                  reportedValue: 165000,
                },
                {
                  value: "$170,000 to less than $180,000",
                  label: "$170,000 to less than $180,000",
                  reportedValue: 175000,
                },
                {
                  value: "$180,000 to less than $190,000",
                  label: "$180,000 to less than $190,000",
                  reportedValue: 185000,
                },
                {
                  value: "$190,000 to less than $200,000",
                  label: "$190,000 to less than $200,000",
                  reportedValue: 195000,
                },
              ],
            },
          ],
        },
        {
          value: "$200,000 to less than $300,000",
          label: "$200,000 to less than $300,000",
          questions: [
            {
              _id: "Question14a",
              num: 35,
              text: "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
              dependency: "isQuestion14aReady",
              optionsType: "select",
              // afterInput: "writeRangeValues",
              afterInput: "Question14a",
              options: [
                {
                  value: "$200,000 to less than $210,000",
                  label: "$200,000 to less than $210,000",
                  reportedValue: 205000,
                },
                {
                  value: "$210,000 to less than $220,000",
                  label: "$210,000 to less than $220,000",
                  reportedValue: 215000,
                },
                {
                  value: "$220,000 to less than $230,000",
                  label: "$220,000 to less than $230,000",
                  reportedValue: 225000,
                },
                {
                  value: "$230,000 to less than $240,000",
                  label: "$230,000 to less than $240,000",
                  reportedValue: 235000,
                },
                {
                  value: "$240,000 to less than $250,000",
                  label: "$240,000 to less than $250,000",
                  reportedValue: 245000,
                },
                {
                  value: "$250,000 to less than $260,000",
                  label: "$250,000 to less than $260,000",
                  reportedValue: 255000,
                },
                {
                  value: "$260,000 to less than $270,000",
                  label: "$260,000 to less than $270,000",
                  reportedValue: 265000,
                },
                {
                  value: "$270,000 to less than $280,000",
                  label: "$270,000 to less than $280,000",
                  reportedValue: 275000,
                },
                {
                  value: "$280,000 to less than $290,000",
                  label: "$280,000 to less than $290,000",
                  reportedValue: 285000,
                },
                {
                  value: "$290,000 to less than $300,000",
                  label: "$290,000 to less than $300,000",
                  reportedValue: 295000,
                },
              ],
            },
          ],
        },
        {
          value: "$300,000 to less than $400,000",
          label: "$300,000 to less than $400,000",
          questions: [
            {
              _id: "Question14a",
              num: 35,
              text: "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
              dependency: "isQuestion14aReady",
              optionsType: "select",
              // afterInput: "writeRangeValues",
              afterInput: "Question14a",
              options: [
                {
                  value: "$300,000 to less than $310,000",
                  label: "$300,000 to less than $310,000",
                  reportedValue: 305000,
                },
                {
                  value: "$310,000 to less than $320,000",
                  label: "$310,000 to less than $320,000",
                  reportedValue: 315000,
                },
                {
                  value: "$320,000 to less than $330,000",
                  label: "$320,000 to less than $330,000",
                  reportedValue: 325000,
                },
                {
                  value: "$330,000 to less than $340,000",
                  label: "$330,000 to less than $340,000",
                  reportedValue: 335000,
                },
                {
                  value: "$340,000 to less than $350,000",
                  label: "$340,000 to less than $350,000",
                  reportedValue: 345000,
                },
                {
                  value: "$350,000 to less than $360,000",
                  label: "$350,000 to less than $360,000",
                  reportedValue: 355000,
                },
                {
                  value: "$360,000 to less than $370,000",
                  label: "$360,000 to less than $370,000",
                  reportedValue: 365000,
                },
                {
                  value: "$370,000 to less than $380,000",
                  label: "$370,000 to less than $380,000",
                  reportedValue: 375000,
                },
                {
                  value: "$380,000 to less than $390,000",
                  label: "$380,000 to less than $390,000",
                  reportedValue: 385000,
                },
                {
                  value: "$390,000 to less than $400,000",
                  label: "$390,000 to less than $400,000",
                  reportedValue: 395000,
                },
              ],
            },
          ],
        },
        {
          value: "$400,000 to less than $500,000",
          label: "$400,000 to less than $500,000",
          questions: [
            {
              _id: "Question14a",
              num: 35,
              text: "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
              dependency: "isQuestion14aReady",
              optionsType: "select",
              // afterInput: "writeRangeValues",
              afterInput: "Question14a",
              options: [
                {
                  value: "$400,000 to less than $410,000",
                  label: "$400,000 to less than $410,000",
                  reportedValue: 405000,
                },
                {
                  value: "$410,000 to less than $420,000",
                  label: "$410,000 to less than $420,000",
                  reportedValue: 415000,
                },
                {
                  value: "$420,000 to less than $430,000",
                  label: "$420,000 to less than $430,000",
                  reportedValue: 425000,
                },
                {
                  value: "$430,000 to less than $440,000",
                  label: "$430,000 to less than $440,000",
                  reportedValue: 435000,
                },
                {
                  value: "$440,000 to less than $450,000",
                  label: "$440,000 to less than $450,000",
                  reportedValue: 445000,
                },
                {
                  value: "$450,000 to less than $460,000",
                  label: "$450,000 to less than $460,000",
                  reportedValue: 455000,
                },
                {
                  value: "$460,000 to less than $470,000",
                  label: "$460,000 to less than $470,000",
                  reportedValue: 465000,
                },
                {
                  value: "$470,000 to less than $480,000",
                  label: "$470,000 to less than $480,000",
                  reportedValue: 475000,
                },
                {
                  value: "$480,000 to less than $490,000",
                  label: "$480,000 to less than $490,000",
                  reportedValue: 485000,
                },
                {
                  value: "$490,000 to less than $500,000",
                  label: "$490,000 to less than $500,000",
                  reportedValue: 495000,
                },
              ],
            },
          ],
        },
        {
          value: "$500,000 to less than $1 million",
          label: "$500,000 to less than $1 million",
          questions: [
            {
              _id: "Question14a",
              num: 35,
              text: "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
              dependency: "isQuestion14aReady",
              optionsType: "select",
              // afterInput: "writeRangeValues",
              afterInput: "Question14a",
              options: [
                {
                  value: "$500,000 to less than $550,000",
                  label: "$500,000 to less than $550,000",
                  reportedValue: 525000,
                },
                {
                  value: "$550,000 to less than $600,000",
                  label: "$550,000 to less than $600,000",
                  reportedValue: 575000,
                },
                {
                  value: "$600,000 to less than $650,000",
                  label: "$600,000 to less than $650,000",
                  reportedValue: 625000,
                },
                {
                  value: "$650,000 to less than $700,000",
                  label: "$650,000 to less than $700,000",
                  reportedValue: 675000,
                },
                {
                  value: "$700,000 to less than $750,000",
                  label: "$700,000 to less than $750,000",
                  reportedValue: 725000,
                },
                {
                  value: "$750,000 to less than $800,000",
                  label: "$750,000 to less than $800,000",
                  reportedValue: 775000,
                },
                {
                  value: "$800,000 to less than $850,000",
                  label: "$800,000 to less than $850,000",
                  reportedValue: 825000,
                },
                {
                  value: "$850,000 to less than $900,000",
                  label: "$850,000 to less than $900,000",
                  reportedValue: 875000,
                },
                {
                  value: "$900,000 to less than $950,000",
                  label: "$900,000 to less than $950,000",
                  reportedValue: 925000,
                },
                {
                  value: "$950,000 to less than $1 million",
                  label: "$950,000 to less than $1 million",
                  reportedValue: 975000,
                },
              ],
            },
          ],
        },
        {
          value: "$1 million to less than $10 million",
          label: "$1 million to less than $10 million",
          questions: [
            {
              _id: "Question14a",
              num: 35,
              text: "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
              dependency: "isQuestion14aReady",
              optionsType: "select",
              // afterInput: "writeRangeValues",
              afterInput: "Question14a",
              options: [
                {
                  value: "$1 million to less than $2 million",
                  label: "$1 million to less than $2 million",
                  reportedValue: 1500000,
                },
                {
                  value: "$2 million to less than $3 million",
                  label: "$2 million to less than $3 million",
                  reportedValue: 2500000,
                },
                {
                  value: "$3 million to less than $4 million",
                  label: "$3 million to less than $4 million",
                  reportedValue: 3500000,
                },
                {
                  value: "$4 million to less than $5 million",
                  label: "$4 million to less than $5 million",
                  reportedValue: 4500000,
                },
                {
                  value: "$5 million to less than $6 million",
                  label: "$5 million to less than $6 million",
                  reportedValue: 5500000,
                },
                {
                  value: "$6 million to less than $7 million",
                  label: "$6 million to less than $7 million",
                  reportedValue: 6500000,
                },
                {
                  value: "$7 million to less than $8 million",
                  label: "$7 million to less than $8 million",
                  reportedValue: 7500000,
                },
                {
                  value: "$8 million to less than $9 million",
                  label: "$8 million to less than $9 million",
                  reportedValue: 8500000,
                },
                {
                  value: "$9 million to less than $10 million",
                  label: "$9 million to less than $10 million",
                  reportedValue: 9500000,
                },
              ],
            },
          ],
        },
        {
          value: "$10 million to less than $100 million",
          label: "$10 million to less than $100 million",
          questions: [
            {
              _id: "Question14a",
              num: 35,
              text: "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
              dependency: "isQuestion14aReady",
              optionsType: "select",
              // afterInput: "writeRangeValues",
              afterInput: "Question14a",
              options: [
                {
                  value: "$10 million to less than $20 million",
                  label: "$10 million to less than $20 million",
                  reportedValue: 15000000,
                },
                {
                  value: "$20 million to less than $30 million",
                  label: "$20 million to less than $30 million",
                  reportedValue: 25000000,
                },
                {
                  value: "$30 million to less than $40 million",
                  label: "$30 million to less than $40 million",
                  reportedValue: 35000000,
                },
                {
                  value: "$40 million to less than $50 million",
                  label: "$40 million to less than $50 million",
                  reportedValue: 45000000,
                },
                {
                  value: "$50 million to less than $60 million",
                  label: "$50 million to less than $60 million",
                  reportedValue: 55000000,
                },
                {
                  value: "$60 million to less than $70 million",
                  label: "$60 million to less than $70 million",
                  reportedValue: 65000000,
                },
                {
                  value: "$70 million to less than $80 million",
                  label: "$70 million to less than $80 million",
                  reportedValue: 75000000,
                },
                {
                  value: "$80 million to less than $90 million",
                  label: "$80 million to less than $90 million",
                  reportedValue: 85000000,
                },
                {
                  value: "$90 million to less than $100 million",
                  label: "$90 million to less than $100 million",
                  reportedValue: 95000000,
                },
              ],
            },
          ],
        },
        {
          value: "$100 million or more",
          label: "$100 million or more",
          questions: [
            {
              _id: "Question14a",
              num: 35,
              text: "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
              dependency: "isQuestion14aReady",
              optionsType: "select",
              // afterInput: "writeRangeValues",
              afterInput: "Question14a",
              options: [
                {
                  value: "$100 million to less than $200 million",
                  label: "$100 million to less than $200 million",
                  reportedValue: 150000000,
                },
                {
                  value: "$200 million to less than $300 million",
                  label: "$200 million to less than $300 million",
                  reportedValue: 250000000,
                },
                {
                  value: "$300 million to less than $400 million",
                  label: "$300 million to less than $400 million",
                  reportedValue: 350000000,
                },
                {
                  value: "$400 million to less than $500 million",
                  label: "$400 million to less than $500 million",
                  reportedValue: 450000000,
                },
                {
                  value: "$500 million to less than $600 million",
                  label: "$500 million to less than $600 million",
                  reportedValue: 550000000,
                },
                {
                  value: "$600 million to less than $700 million",
                  label: "$600 million to less than $700 million",
                  reportedValue: 650000000,
                },
                {
                  value: "$700 million to less than $800 million",
                  label: "$700 million to less than $800 million",
                  reportedValue: 750000000,
                },
                {
                  value: "$800 million to less than $900 million",
                  label: "$800 million to less than $900 million",
                  reportedValue: 850000000,
                },
                {
                  value: "$900 million or more",
                  label: "$900 million or more",
                  reportedValue: 950000000,
                },
              ],
            },
          ],
        },
      ],
      substitutedValues: [
        {
          name: "start", // Required, string to replace
          source: "#Question4c", // Required, question source to use
          format: "monthYear", // Optional
        },
        {
          name: "end", // Required, string to replace
          source: "#Question12", // Required, question source to use
          format: "monthYear", // Optional
          altText: "today", // Optional, replacement text when operation results in altTextValue
        },
        {
          name: "_recoveryText_",
          source: "#Question8",
          // Optional, a condition to test the source property against to
          // determine whether to replace or remove
          condition:
            '["Not recovered at all", "Recovered somewhat, but not fully"]',
          altText: " (your recovery period)",
        },
      ],
    },

    {
      _id: "Question15",
      num: 36,
      text: "Between q15rangeBegin and q15rangeEnd, what percent of that expected sales revenue was lost due to the disaster?",
      // "What percent of that expected sales revenue was lost due to the disaster?",
      dependency: "isQuestion15Ready",
      substitutedValues: [
        { name: "q15rangeBegin", source: "q15rangeBegin" },
        { name: "q15rangeEnd", source: "q15rangeEnd" },
        {
          name: "expected_sales_revenue_midpoint",
          // source:
          //   '["#Question14a","#Question14b","#Question14c","#Question14d","#Question14e","#Question14f","#Question14g","#Question14h","#Question14i","#Question14j","#Question14k","#Question14l","#Question14m","#Question14n"]',
          source: "#Question14a",
          value: "reportedValue",
          format: "currency",
        },
      ],
      optionsType: "slider",
      // afterInput: "setTotalRevenueLostDuringTheDisaster",
      afterInput: "Question15",
    },

    {
      _id: "Question16",
      num: 37,
      text: "Based on your answers, we estimate that your business lost about TotalRevenueLostDuringTheDisaster due to the disaster. Does that sound about right?",
      dependency: "isQuestion16Ready",
      substitutedValues: [
        {
          name: "TotalRevenueLostDuringTheDisaster",
          source: "totalRevenueLostDuringTheDisaster",
          format: "currency",
        },
      ],
      optionsType: "select",
      options: [
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
      ],
      // afterInput: "confirmTotalRevenueLostDuringTheDisaster",
      afterInput: "Question16",
    },

    {
      _id: "Question16a",
      num: 38,
      text: "Please provide your best estimate of your affected business' lost sales revenue due to the disaster:",
      dependency: "isQuestion16aReady",
      substitutedValues: [
        {
          name: "TotalRevenueLostDuringTheDisaster",
          source: "totalRevenueLostDuringTheDisaster",
          format: "currency",
        },
      ],
      optionsType: "slider-large",
      max: "Question14a",
      renderOptions: "sliderOptionsFromValues",
      afterInput: "Question16a",
      afterDestroy: "Question16a",
    },

    // {
    //   _id: "Question17",
    //   num: 39,
    //   description:
    //     "Throughout this survey, we will be asking you some questions about your affected business's recovery period after the disaster you have specified. When asking about your recovery period we mean from recovery_begin until recovery_end.",
    //   text:
    //     "How much Sales Revenue (in dollars) do you estimate your affected business lost in total during this RECOVERY period?",
    //   dependency: "isQuestion17Ready",
    //   afterInput: "Question17",
    //   substitutedValues: [
    //     { name: "recovery_begin", source: "#Question4c", format: "monthYear" },
    //     { name: "recovery_end", source: "#Question12", format: "monthYear" },
    //   ],
    //   optionsType: "select",
    //   options: [
    //     {
    //       value: "Less than $5,000",
    //       label: "Less than $5,000",
    //       questions: [
    //         {
    //           _id: "Question17a",
    //           num: 40,
    //           text:
    //             "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
    //           dependency: "isQuestion17aReady",
    //           optionsType: "select",
    //           // afterInput: "writeRangeValues",
    //           options: [
    //             {
    //               value: "$500 to less than $1,000",
    //               label: "$500 to less than $1,000",
    //               reportedValue: 750,
    //             },
    //             {
    //               value: "$1,000 to less than $1,500",
    //               label: "$1,000 to less than $1,500",
    //               reportedValue: 1250,
    //             },
    //             {
    //               value: "$1,500 to less than $2,000",
    //               label: "$1,500 to less than $2,000",
    //               reportedValue: 1750,
    //             },
    //             {
    //               value: "$2,000 to less than $2,500",
    //               label: "$2,000 to less than $2,500",
    //               reportedValue: 2250,
    //             },
    //             {
    //               value: "$2,500 to less than $3,000",
    //               label: "$2,500 to less than $3,000",
    //               reportedValue: 2750,
    //             },
    //             {
    //               value: "$3,000 to less than $3,500",
    //               label: "$3,000 to less than $3,500",
    //               reportedValue: 3250,
    //             },
    //             {
    //               value: "$3,500 to less than $4,000",
    //               label: "$3,500 to less than $4,000",
    //               reportedValue: 3750,
    //             },
    //             {
    //               value: "$4,000 to less than $4,500",
    //               label: "$4,000 to less than $4,500",
    //               reportedValue: 4250,
    //             },
    //             {
    //               value: "$4,500 to less than $5,000",
    //               label: "$4,500 to less than $5,000",
    //               reportedValue: 4750,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       value: "$5,000 to less than $10,000",
    //       label: "$5,000 to less than $10,000",
    //       questions: [
    //         {
    //           _id: "Question17a",
    //           num: 40,
    //           text:
    //             "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
    //           dependency: "isQuestion17aReady",
    //           optionsType: "select",
    //           // afterInput: "writeRangeValues",
    //           options: [
    //             {
    //               value: "$5,000 to less than $5,500",
    //               label: "$5,000 to less than $5,500",
    //               reportedValue: 5250,
    //             },
    //             {
    //               value: "$5,500 to less than $6,000",
    //               label: "$5,500 to less than $6,000",
    //               reportedValue: 5750,
    //             },
    //             {
    //               value: "$6,000 to less than $6,500",
    //               label: "$6,000 to less than $6,500",
    //               reportedValue: 6250,
    //             },
    //             {
    //               value: "$6,500 to less than $7,000",
    //               label: "$6,500 to less than $7,000",
    //               reportedValue: 6750,
    //             },
    //             {
    //               value: "$7,000 to less than $7,500",
    //               label: "$7,000 to less than $7,500",
    //               reportedValue: 7250,
    //             },
    //             {
    //               value: "$7,500 to less than $8,000",
    //               label: "$7,500 to less than $8,000",
    //               reportedValue: 7750,
    //             },
    //             {
    //               value: "$8,000 to less than $8,500",
    //               label: "$8,000 to less than $8,500",
    //               reportedValue: 8250,
    //             },
    //             {
    //               value: "$8,500 to less than $9,000",
    //               label: "$8,500 to less than $9,000",
    //               reportedValue: 8750,
    //             },
    //             {
    //               value: "$9,000 to less than $9,500",
    //               label: "$9,000 to less than $9,500",
    //               reportedValue: 8250,
    //             },
    //             {
    //               value: "$9,500 to less than $10,000",
    //               label: "$9,500 to less than $10,000",
    //               reportedValue: 9750,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       value: "$10,000 to less than $25,000",
    //       label: "$10,000 to less than $25,000",
    //       questions: [
    //         {
    //           _id: "Question17a",
    //           num: 40,
    //           text:
    //             "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
    //           dependency: "isQuestion17aReady",
    //           optionsType: "select",
    //           // afterInput: "writeRangeValues",
    //           options: [
    //             {
    //               value: "$10,000 to less than $11,000",
    //               label: "$10,000 to less than $11,000",
    //               reportedValue: 10500,
    //             },
    //             {
    //               value: "$11,000 to less than $12,000",
    //               label: "$11,000 to less than $12,000",
    //               reportedValue: 11500,
    //             },
    //             {
    //               value: "$12,000 to less than $13,000",
    //               label: "$12,000 to less than $13,000",
    //               reportedValue: 12500,
    //             },
    //             {
    //               value: "$13,000 to less than $14,000",
    //               label: "$13,000 to less than $14,000",
    //               reportedValue: 13500,
    //             },
    //             {
    //               value: "$14,000 to less than $15,000",
    //               label: "$14,000 to less than $15,000",
    //               reportedValue: 14500,
    //             },
    //             {
    //               value: "$15,000 to less than $16,000",
    //               label: "$15,000 to less than $16,000",
    //               reportedValue: 15500,
    //             },
    //             {
    //               value: "$16,000 to less than $17,000",
    //               label: "$16,000 to less than $17,000",
    //               reportedValue: 16500,
    //             },
    //             {
    //               value: "$17,000 to less than $18,000",
    //               label: "$17,000 to less than $18,000",
    //               reportedValue: 17500,
    //             },
    //             {
    //               value: "$18,000 to less than $19,000",
    //               label: "$18,000 to less than $19,000",
    //               reportedValue: 18500,
    //             },
    //             {
    //               value: "$19,000 to less than $20,000",
    //               label: "$19,000 to less than $20,000",
    //               reportedValue: 19500,
    //             },
    //             {
    //               value: "$20,000 to less than $21,000",
    //               label: "$20,000 to less than $21,000",
    //               reportedValue: 20500,
    //             },
    //             {
    //               value: "$21,000 to less than $22,000",
    //               label: "$21,000 to less than $22,000",
    //               reportedValue: 21500,
    //             },
    //             {
    //               value: "$22,000 to less than $23,000",
    //               label: "$22,000 to less than $23,000",
    //               reportedValue: 22500,
    //             },
    //             {
    //               value: "$23,000 to less than $24,000",
    //               label: "$23,000 to less than $24,000",
    //               reportedValue: 23500,
    //             },
    //             {
    //               value: "$24,000 to less than $25,000",
    //               label: "$24,000 to less than $25,000",
    //               reportedValue: 24500,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       value: "$25,000 to less than $50,000",
    //       label: "$25,000 to less than $50,000",
    //       questions: [
    //         {
    //           _id: "Question17a",
    //           num: 40,
    //           text:
    //             "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
    //           dependency: "isQuestion17aReady",
    //           optionsType: "select",
    //           // afterInput: "writeRangeValues",
    //           options: [
    //             {
    //               value: "$25,000 to less than $27,500",
    //               label: "$25,000 to less than $27,500",
    //               reportedValue: 26250,
    //             },
    //             {
    //               value: "$27,500 to less than $30,000",
    //               label: "$27,500 to less than $30,000",
    //               reportedValue: 28750,
    //             },
    //             {
    //               value: "$30,000 to less than $32,500",
    //               label: "$30,000 to less than $32,500",
    //               reportedValue: 31250,
    //             },
    //             {
    //               value: "$32,500 to less than $35,000",
    //               label: "$32,500 to less than $35,000",
    //               reportedValue: 33750,
    //             },
    //             {
    //               value: "$35,000 to less than $37,500",
    //               label: "$35,000 to less than $37,500",
    //               reportedValue: 36250,
    //             },
    //             {
    //               value: "$37,500 to less than $40,000",
    //               label: "$37,500 to less than $40,000",
    //               reportedValue: 38750,
    //             },
    //             {
    //               value: "$40,000 to less than $42,500",
    //               label: "$40,000 to less than $42,500",
    //               reportedValue: 41250,
    //             },
    //             {
    //               value: "$42,500 to less than $45,000",
    //               label: "$42,500 to less than $45,000",
    //               reportedValue: 43750,
    //             },
    //             {
    //               value: "$45,000 to less than $47,500",
    //               label: "$45,000 to less than $47,500",
    //               reportedValue: 46250,
    //             },
    //             {
    //               value: "$47,500 to less than $50,000",
    //               label: "$47,500 to less than $50,000",
    //               reportedValue: 48750,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       value: "$50,000 to less than $75,000",
    //       label: "$50,000 to less than $75,000",
    //       questions: [
    //         {
    //           _id: "Question17a",
    //           num: 40,
    //           text:
    //             "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
    //           dependency: "isQuestion17aReady",
    //           optionsType: "select",
    //           // afterInput: "writeRangeValues",
    //           options: [
    //             {
    //               value: "$50,000 to less than $52,500",
    //               label: "$50,000 to less than $52,500",
    //               reportedValue: 51250,
    //             },
    //             {
    //               value: "$52,500 to less than $55,000",
    //               label: "$52,500 to less than $55,000",
    //               reportedValue: 53750,
    //             },
    //             {
    //               value: "$55,000 to less than $57,500",
    //               label: "$55,000 to less than $57,500",
    //               reportedValue: 56250,
    //             },
    //             {
    //               value: "$57,500 to less than $60,000",
    //               label: "$57,500 to less than $60,000",
    //               reportedValue: 58750,
    //             },
    //             {
    //               value: "$60,000 to less than $62,500",
    //               label: "$60,000 to less than $62,500",
    //               reportedValue: 61250,
    //             },
    //             {
    //               value: "$62,500 to less than $65,000",
    //               label: "$62,500 to less than $65,000",
    //               reportedValue: 63750,
    //             },
    //             {
    //               value: "$65,000 to less than $67,500",
    //               label: "$65,000 to less than $67,500",
    //               reportedValue: 66250,
    //             },
    //             {
    //               value: "$67,500 to less than $70,000",
    //               label: "$67,500 to less than $70,000",
    //               reportedValue: 68750,
    //             },
    //             {
    //               value: "$70,000 to less than $72,500",
    //               label: "$70,000 to less than $72,500",
    //               reportedValue: 71250,
    //             },
    //             {
    //               value: "$72,500 to less than $75,000",
    //               label: "$72,500 to less than $75,000",
    //               reportedValue: 73750,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       value: "$75,000 to less than $100,000",
    //       label: "$75,000 to less than $100,000",
    //       questions: [
    //         {
    //           _id: "Question17a",
    //           num: 40,
    //           text:
    //             "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
    //           dependency: "isQuestion17aReady",
    //           optionsType: "select",
    //           // afterInput: "writeRangeValues",
    //           options: [
    //             {
    //               value: "$75,000 to less than $77,500",
    //               label: "$75,000 to less than $77,500",
    //               reportedValue: 75250,
    //             },
    //             {
    //               value: "$77,500 to less than $80,000",
    //               label: "$77,500 to less than $80,000",
    //               reportedValue: 78750,
    //             },
    //             {
    //               value: "$80,000 to less than $82,500",
    //               label: "$80,000 to less than $82,500",
    //               reportedValue: 81250,
    //             },
    //             {
    //               value: "$82,500 to less than $85,000",
    //               label: "$82,500 to less than $85,000",
    //               reportedValue: 83750,
    //             },
    //             {
    //               value: "$85,000 to less than $87,500",
    //               label: "$85,000 to less than $87,500",
    //               reportedValue: 86250,
    //             },
    //             {
    //               value: "$87,500 to less than $90,000",
    //               label: "$87,500 to less than $90,000",
    //               reportedValue: 88750,
    //             },
    //             {
    //               value: "$90,000 to less than $92,500",
    //               label: "$90,000 to less than $92,500",
    //               reportedValue: 91250,
    //             },
    //             {
    //               value: "$92,500 to less than $95,000",
    //               label: "$92,500 to less than $95,000",
    //               reportedValue: 93750,
    //             },
    //             {
    //               value: "$95,000 to less than $97,500",
    //               label: "$95,000 to less than $97,500",
    //               reportedValue: 96250,
    //             },
    //             {
    //               value: "$97,500 to less than $100,000",
    //               label: "$97,500 to less than $100,000",
    //               reportedValue: 98750,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       value: "$100,000 to less than $200,000",
    //       label: "$100,000 to less than $200,000",
    //       questions: [
    //         {
    //           _id: "Question17a",
    //           num: 40,
    //           text:
    //             "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
    //           dependency: "isQuestion17aReady",
    //           optionsType: "select",
    //           // afterInput: "writeRangeValues",
    //           options: [
    //             {
    //               value: "$100,000 to less than $110,000",
    //               label: "$100,000 to less than $110,000",
    //               reportedValue: 105000,
    //             },
    //             {
    //               value: "$110,000 to less than $120,000",
    //               label: "$110,000 to less than $120,000",
    //               reportedValue: 115000,
    //             },
    //             {
    //               value: "$120,000 to less than $130,000",
    //               label: "$120,000 to less than $130,000",
    //               reportedValue: 125000,
    //             },
    //             {
    //               value: "$130,000 to less than $140,000",
    //               label: "$130,000 to less than $140,000",
    //               reportedValue: 135000,
    //             },
    //             {
    //               value: "$140,000 to less than $150,000",
    //               label: "$140,000 to less than $150,000",
    //               reportedValue: 145000,
    //             },
    //             {
    //               value: "$150,000 to less than $160,000",
    //               label: "$150,000 to less than $160,000",
    //               reportedValue: 155000,
    //             },
    //             {
    //               value: "$160,000 to less than $170,000",
    //               label: "$160,000 to less than $170,000",
    //               reportedValue: 165000,
    //             },
    //             {
    //               value: "$170,000 to less than $180,000",
    //               label: "$170,000 to less than $180,000",
    //               reportedValue: 175000,
    //             },
    //             {
    //               value: "$180,000 to less than $190,000",
    //               label: "$180,000 to less than $190,000",
    //               reportedValue: 185000,
    //             },
    //             {
    //               value: "$190,000 to less than $200,000",
    //               label: "$190,000 to less than $200,000",
    //               reportedValue: 195000,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       value: "$200,000 to less than $300,000",
    //       label: "$200,000 to less than $300,000",
    //       questions: [
    //         {
    //           _id: "Question17a",
    //           num: 40,
    //           text:
    //             "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
    //           dependency: "isQuestion17aReady",
    //           optionsType: "select",
    //           // afterInput: "writeRangeValues",
    //           options: [
    //             {
    //               value: "$200,000 to less than $210,000",
    //               label: "$200,000 to less than $210,000",
    //               reportedValue: 205000,
    //             },
    //             {
    //               value: "$210,000 to less than $220,000",
    //               label: "$210,000 to less than $220,000",
    //               reportedValue: 215000,
    //             },
    //             {
    //               value: "$220,000 to less than $230,000",
    //               label: "$220,000 to less than $230,000",
    //               reportedValue: 225000,
    //             },
    //             {
    //               value: "$230,000 to less than $240,000",
    //               label: "$230,000 to less than $240,000",
    //               reportedValue: 235000,
    //             },
    //             {
    //               value: "$240,000 to less than $250,000",
    //               label: "$240,000 to less than $250,000",
    //               reportedValue: 245000,
    //             },
    //             {
    //               value: "$250,000 to less than $260,000",
    //               label: "$250,000 to less than $260,000",
    //               reportedValue: 255000,
    //             },
    //             {
    //               value: "$260,000 to less than $270,000",
    //               label: "$260,000 to less than $270,000",
    //               reportedValue: 265000,
    //             },
    //             {
    //               value: "$270,000 to less than $280,000",
    //               label: "$270,000 to less than $280,000",
    //               reportedValue: 275000,
    //             },
    //             {
    //               value: "$280,000 to less than $290,000",
    //               label: "$280,000 to less than $290,000",
    //               reportedValue: 285000,
    //             },
    //             {
    //               value: "$290,000 to less than $300,000",
    //               label: "$290,000 to less than $300,000",
    //               reportedValue: 295000,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       value: "$300,000 to less than $400,000",
    //       label: "$300,000 to less than $400,000",
    //       questions: [
    //         {
    //           _id: "Question17a",
    //           num: 40,
    //           text:
    //             "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
    //           dependency: "isQuestion17aReady",
    //           optionsType: "select",
    //           // afterInput: "writeRangeValues",
    //           options: [
    //             {
    //               value: "$300,000 to less than $310,000",
    //               label: "$300,000 to less than $310,000",
    //               reportedValue: 305000,
    //             },
    //             {
    //               value: "$310,000 to less than $320,000",
    //               label: "$310,000 to less than $320,000",
    //               reportedValue: 315000,
    //             },
    //             {
    //               value: "$320,000 to less than $330,000",
    //               label: "$320,000 to less than $330,000",
    //               reportedValue: 325000,
    //             },
    //             {
    //               value: "$330,000 to less than $340,000",
    //               label: "$330,000 to less than $340,000",
    //               reportedValue: 335000,
    //             },
    //             {
    //               value: "$340,000 to less than $350,000",
    //               label: "$340,000 to less than $350,000",
    //               reportedValue: 345000,
    //             },
    //             {
    //               value: "$350,000 to less than $360,000",
    //               label: "$350,000 to less than $360,000",
    //               reportedValue: 355000,
    //             },
    //             {
    //               value: "$360,000 to less than $370,000",
    //               label: "$360,000 to less than $370,000",
    //               reportedValue: 365000,
    //             },
    //             {
    //               value: "$370,000 to less than $380,000",
    //               label: "$370,000 to less than $380,000",
    //               reportedValue: 375000,
    //             },
    //             {
    //               value: "$380,000 to less than $390,000",
    //               label: "$380,000 to less than $390,000",
    //               reportedValue: 385000,
    //             },
    //             {
    //               value: "$390,000 to less than $400,000",
    //               label: "$390,000 to less than $400,000",
    //               reportedValue: 395000,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       value: "$400,000 to less than $500,000",
    //       label: "$400,000 to less than $500,000",
    //       questions: [
    //         {
    //           _id: "Question17a",
    //           num: 40,
    //           text:
    //             "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
    //           dependency: "isQuestion17aReady",
    //           optionsType: "select",
    //           // afterInput: "writeRangeValues",
    //           options: [
    //             {
    //               value: "$400,000 to less than $410,000",
    //               label: "$400,000 to less than $410,000",
    //               reportedValue: 405000,
    //             },
    //             {
    //               value: "$410,000 to less than $420,000",
    //               label: "$410,000 to less than $420,000",
    //               reportedValue: 415000,
    //             },
    //             {
    //               value: "$420,000 to less than $430,000",
    //               label: "$420,000 to less than $430,000",
    //               reportedValue: 425000,
    //             },
    //             {
    //               value: "$430,000 to less than $440,000",
    //               label: "$430,000 to less than $440,000",
    //               reportedValue: 435000,
    //             },
    //             {
    //               value: "$440,000 to less than $450,000",
    //               label: "$440,000 to less than $450,000",
    //               reportedValue: 445000,
    //             },
    //             {
    //               value: "$450,000 to less than $460,000",
    //               label: "$450,000 to less than $460,000",
    //               reportedValue: 455000,
    //             },
    //             {
    //               value: "$460,000 to less than $470,000",
    //               label: "$460,000 to less than $470,000",
    //               reportedValue: 465000,
    //             },
    //             {
    //               value: "$470,000 to less than $480,000",
    //               label: "$470,000 to less than $480,000",
    //               reportedValue: 475000,
    //             },
    //             {
    //               value: "$480,000 to less than $490,000",
    //               label: "$480,000 to less than $490,000",
    //               reportedValue: 485000,
    //             },
    //             {
    //               value: "$490,000 to less than $500,000",
    //               label: "$490,000 to less than $500,000",
    //               reportedValue: 495000,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       value: "$500,000 to less than $1 million",
    //       label: "$500,000 to less than $1 million",
    //       questions: [
    //         {
    //           _id: "Question17a",
    //           num: 40,
    //           text:
    //             "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
    //           dependency: "isQuestion17aReady",
    //           optionsType: "select",
    //           // afterInput: "writeRangeValues",
    //           options: [
    //             {
    //               value: "$500,000 to less than $550,000",
    //               label: "$500,000 to less than $550,000",
    //               reportedValue: 525000,
    //             },
    //             {
    //               value: "$550,000 to less than $600,000",
    //               label: "$550,000 to less than $600,000",
    //               reportedValue: 575000,
    //             },
    //             {
    //               value: "$600,000 to less than $650,000",
    //               label: "$600,000 to less than $650,000",
    //               reportedValue: 625000,
    //             },
    //             {
    //               value: "$650,000 to less than $700,000",
    //               label: "$650,000 to less than $700,000",
    //               reportedValue: 675000,
    //             },
    //             {
    //               value: "$700,000 to less than $750,000",
    //               label: "$700,000 to less than $750,000",
    //               reportedValue: 725000,
    //             },
    //             {
    //               value: "$750,000 to less than $800,000",
    //               label: "$750,000 to less than $800,000",
    //               reportedValue: 775000,
    //             },
    //             {
    //               value: "$800,000 to less than $850,000",
    //               label: "$800,000 to less than $850,000",
    //               reportedValue: 825000,
    //             },
    //             {
    //               value: "$850,000 to less than $900,000",
    //               label: "$850,000 to less than $900,000",
    //               reportedValue: 875000,
    //             },
    //             {
    //               value: "$900,000 to less than $950,000",
    //               label: "$900,000 to less than $950,000",
    //               reportedValue: 925000,
    //             },
    //             {
    //               value: "$950,000 to less than $1 million",
    //               label: "$950,000 to less than $1 million",
    //               reportedValue: 975000,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       value: "$1 million to less than $10 million",
    //       label: "$1 million to less than $10 million",
    //       questions: [
    //         {
    //           _id: "Question17a",
    //           num: 40,
    //           text:
    //             "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
    //           dependency: "isQuestion17aReady",
    //           optionsType: "select",
    //           // afterInput: "writeRangeValues",
    //           options: [
    //             {
    //               value: "$1 million to less than $2 million",
    //               label: "$1 million to less than $2 million",
    //               reportedValue: 1500000,
    //             },
    //             {
    //               value: "$2 million to less than $3 million",
    //               label: "$2 million to less than $3 million",
    //               reportedValue: 2500000,
    //             },
    //             {
    //               value: "$3 million to less than $4 million",
    //               label: "$3 million to less than $4 million",
    //               reportedValue: 3500000,
    //             },
    //             {
    //               value: "$4 million to less than $5 million",
    //               label: "$4 million to less than $5 million",
    //               reportedValue: 4500000,
    //             },
    //             {
    //               value: "$5 million to less than $6 million",
    //               label: "$5 million to less than $6 million",
    //               reportedValue: 5500000,
    //             },
    //             {
    //               value: "$6 million to less than $7 million",
    //               label: "$6 million to less than $7 million",
    //               reportedValue: 6500000,
    //             },
    //             {
    //               value: "$7 million to less than $8 million",
    //               label: "$7 million to less than $8 million",
    //               reportedValue: 7500000,
    //             },
    //             {
    //               value: "$8 million to less than $9 million",
    //               label: "$8 million to less than $9 million",
    //               reportedValue: 8500000,
    //             },
    //             {
    //               value: "$9 million to less than $10 million",
    //               label: "$9 million to less than $10 million",
    //               reportedValue: 9500000,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       value: "$10 million to less than $100 million",
    //       label: "$10 million to less than $100 million",
    //       questions: [
    //         {
    //           _id: "Question17a",
    //           num: 40,
    //           text:
    //             "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
    //           dependency: "isQuestion17aReady",
    //           optionsType: "select",
    //           // afterInput: "writeRangeValues",
    //           options: [
    //             {
    //               value: "$10 million to less than $20 million",
    //               label: "$10 million to less than $20 million",
    //               reportedValue: 15000000,
    //             },
    //             {
    //               value: "$20 million to less than $30 million",
    //               label: "$20 million to less than $30 million",
    //               reportedValue: 25000000,
    //             },
    //             {
    //               value: "$30 million to less than $40 million",
    //               label: "$30 million to less than $40 million",
    //               reportedValue: 35000000,
    //             },
    //             {
    //               value: "$40 million to less than $50 million",
    //               label: "$40 million to less than $50 million",
    //               reportedValue: 45000000,
    //             },
    //             {
    //               value: "$50 million to less than $60 million",
    //               label: "$50 million to less than $60 million",
    //               reportedValue: 55000000,
    //             },
    //             {
    //               value: "$60 million to less than $70 million",
    //               label: "$60 million to less than $70 million",
    //               reportedValue: 65000000,
    //             },
    //             {
    //               value: "$70 million to less than $80 million",
    //               label: "$70 million to less than $80 million",
    //               reportedValue: 75000000,
    //             },
    //             {
    //               value: "$80 million to less than $90 million",
    //               label: "$80 million to less than $90 million",
    //               reportedValue: 85000000,
    //             },
    //             {
    //               value: "$90 million to less than $100 million",
    //               label: "$90 million to less than $100 million",
    //               reportedValue: 95000000,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       value: "$100 million or more",
    //       label: "$100 million or more",
    //       questions: [
    //         {
    //           _id: "Question17a",
    //           num: 40,
    //           text:
    //             "And <em>more specifically</em>, which range below is closest to the expected Sales Revenue (in dollars) for the period specified previously?",
    //           dependency: "isQuestion17aReady",
    //           optionsType: "select",
    //           // afterInput: "writeRangeValues",
    //           options: [
    //             {
    //               value: "$100 million to less than $200 million",
    //               label: "$100 million to less than $200 million",
    //               reportedValue: 150000000,
    //             },
    //             {
    //               value: "$200 million to less than $300 million",
    //               label: "$200 million to less than $300 million",
    //               reportedValue: 250000000,
    //             },
    //             {
    //               value: "$300 million to less than $400 million",
    //               label: "$300 million to less than $400 million",
    //               reportedValue: 350000000,
    //             },
    //             {
    //               value: "$400 million to less than $500 million",
    //               label: "$400 million to less than $500 million",
    //               reportedValue: 450000000,
    //             },
    //             {
    //               value: "$500 million to less than $600 million",
    //               label: "$500 million to less than $600 million",
    //               reportedValue: 550000000,
    //             },
    //             {
    //               value: "$600 million to less than $700 million",
    //               label: "$600 million to less than $700 million",
    //               reportedValue: 650000000,
    //             },
    //             {
    //               value: "$700 million to less than $800 million",
    //               label: "$700 million to less than $800 million",
    //               reportedValue: 750000000,
    //             },
    //             {
    //               value: "$800 million to less than $900 million",
    //               label: "$800 million to less than $900 million",
    //               reportedValue: 850000000,
    //             },
    //             {
    //               value: "$900 million or more",
    //               label: "$900 million or more",
    //               reportedValue: 950000000,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       value: "Don't know",
    //       label: "Don't know",
    //     },
    //   ],
    // },

    {
      _id: "Question18",
      num: 41,
      text: "You have reported a value for sales revenue losses that exceeds your total sales revenue losses for your entire recovery period. Do you think this is due to:",
      dependency: "isQuestion18Ready",
      optionsType: "select",
      options: [
        {
          value:
            "An error in your reporting of expected sales revenues had there been no disaster",
          label:
            "An error in your reporting of expected sales revenues had there been no disaster",
        },
        {
          value:
            "An error in your reporting of total sales revenues lost over the duration of your recovery period",
          label:
            "An error in your reporting of total sales revenues lost over the duration of your recovery period",
        },
      ],
      // afterInput: "clearSalesRevenueLosses",
      afterInput: "Question18",
    },

    {
      _id: "Question19",
      num: 42,
      text: "Which of the following statements best describes your business's RECOVERY period over time?",
      dependency: "isQuestion19Ready",
      optionsType: "select",
      options: [
        {
          value:
            "My business's recovery was steady, meaning that my business progressed approximately in equal steps and returned to pre-disaster levels of Sales Revenue",
          label:
            "My business's recovery was steady, meaning that my business progressed approximately in equal steps and returned to pre-disaster levels of Sales Revenue",
        },
        {
          value:
            "My business's recovery had a slow start, and then quickly returned to pre-disaster levels of Sales Revenue",
          label:
            "My business's recovery had a slow start, and then quickly returned to pre-disaster levels of Sales Revenue",
        },
        {
          value:
            "My business's recovery had a quick start, but then tapered off before returning to pre-disaster levels of Sales Revenue",
          label:
            "My business's recovery had a quick start, but then tapered off before returning to pre-disaster levels of Sales Revenue",
        },
      ],
    },

    {
      _id: "Question20",
      num: 43,
      text: "Which of the following statements best describes your affected business's RECOVERY period following the disaster, from _Question4c_ to today?",
      dependency: "isQuestion20Ready",
      optionsType: "select",
      substitutedValues: [
        {
          name: "_Question4c_", // Required, string to replace
          source: "#Question4c", // Required, question source to use
          format: "monthYear", // Optional
        },
      ],
      options: [
        {
          value:
            "My business's recovery has been steady, meaning that my business is smoothly and incrementally returning to pre-disaster levels of Sales Revenue",
          label:
            "My business's recovery has been steady, meaning that my business is smoothly and incrementally returning to pre-disaster levels of Sales Revenue",
        },
        {
          value:
            "My business's recovery has had a slow start but appears to be picking up and getting closer to pre- disaster levels of Sales Revenue",
          label:
            "My business's recovery has had a slow start but appears to be picking up and getting closer to pre- disaster levels of Sales Revenue",
        },
        {
          value:
            "My business's recovery has had a quick start but appears to be slowly returning to pre-disaster levels of Sales Revenue",
          label:
            "My business's recovery has had a quick start but appears to be slowly returning to pre-disaster levels of Sales Revenue",
        },
      ],
    },

    {
      _id: "Question21",
      num: 44,
      description:
        "The following questions identify approaches or tactics that businesses can utilize to improve resilience in the aftermath of disasters and disruptive events. Please select the tactics that your affected business used DURING ITS RECOVERY PERIOD? Please select all that apply.",
      text: " ",
      dependency: "isQuestion21Ready",
      afterInput: "Question21",
      optionsType: "checkboxgroup",
      optionsModifier: "random",
      options: [
        {
          value: "CONSERVATION",
          label: "<strong>CONSERVATION:</strong>",
          extendedLabel:
            "Maintaining intended production or service levels using lower amounts of an input or inputs (e.g., achieving the same level of production using less water, electricity or workers, without substituting other inputs for them).",
        },
        {
          value: "RESOURCE ISOLATION",
          label: "<strong>RESOURCE ISOLATION:</strong>",
          extendedLabel:
            "Modifying a portion of your business operations to run without a critical input (e.g., following the disaster an office building could still be operational without water). This can include the isolation existing before the hurricane or your extra effort to isolate it post-event.",
        },
        {
          value: "INPUT SUBSTITUTION",
          label: "<strong>INPUT SUBSTITUTION:</strong>",
          extendedLabel:
            "Replacing a production input in short supply with another (e.g., replacing electricity by natural gas, water provided by pipeline with bottled or trucked water, whole milk with powdered milk, employees for tasks previously performed by machinery.",
        },
        {
          value: "INVENTORIES",
          label: "<strong>INVENTORIES:</strong>",
          extendedLabel:
            "Continuing business operations even when a critical input is in short supply by using emergency stockpiles and ordinary working supplies of production inputs (e.g., water tanks, canned goods, stockpiled materials in general).",
        },
        {
          value: "EXCESS CAPACITY",
          label: "<strong>EXCESS CAPACITY:</strong>",
          extendedLabel:
            "Using a plant or equipment that was idle before the disaster in place of a damaged plant and equipment (e.g., bring on-line physical assets not previously in use; such assets might include computers, equipment, vehicles, and vacant buildings).",
        },

        {
          value: "RELOCATION",
          label: "<strong>RELOCATION:</strong>",
          extendedLabel:
            'Moving some or all of the business activity to a new location (either temporary or permanent), including shifting data from onsite to "cloud" storage.',
        },
        {
          value: "MANAGEMENT EFFECTIVENESS",
          label: "<strong>MANAGEMENT EFFECTIVENESS:</strong>",
          extendedLabel:
            "Improving the efficiency of your business in the aftermath of the disaster (e.g., allowing for flexibility in business operations/procedures to minimize red tape during recovery, offering flexible working hours, minimizing reporting requirements or monitoring to facilitate more efficient or responsive operations).",
        },
        {
          value: "IMPORT SUBSTITUTION",
          label: "<strong>IMPORT SUBSTITUTION:</strong>",
          extendedLabel:
            "Importing some of your needed production inputs when you cannot obtain them from your usual local or regional suppliers, including new contractual arrangements (e.g., buying your materials or supplies from other regions or countries).",
        },
        {
          value: "TECHNOLOGICAL CHANGE",
          label: "<strong>TECHNOLOGICAL CHANGE:</strong>",
          extendedLabel:
            "Improvising all or part of your production process without requiring a major investment expenditure (e.g., replacing two food preparation kitchens with one, replacing your paper accounting system with an automated one).",
        },
        {
          value: "PRODUCTION RECAPTURE",
          label: "<strong>PRODUCTION RECAPTURE:</strong>",
          extendedLabel:
            "Making up for lost production by working overtime or extra shifts. This must involve actual production and not include the selling of goods and services that were previously produced but could not be sold because of a slump in demand (e.g., adding an additional shift for employees or having them work additional overtime hours).",
        },
        {
          value: "RESOURCE SHARING",
          label: "<strong>RESOURCE SHARING:</strong>",
          extendedLabel:
            "Hastening recovery through mechanisms such as bargaining (e.g., renegotiating supply contracts with key suppliers), the selective exchange of certain resources - short-term agreements for a defined period of time with other organizations (e.g., the utilization of facilities in exchange for the provision of any service or any other resource), creating new partnerships (e.g., building relationships with other businesses in order to share information and/or expertise), and resource sharing (e.g., joint ventures in order to bid for public contracts).",
        },
        // {
        //   value: "NONE",
        //   label: "<strong>OTHER:</strong>",
        //   extendedLabel: "None of the other options.",
        //   position: "last", // Optional, can only have a value of 'first' or 'last'
        // },
      ],
    },

    // {
    //   _id: "Question22",
    //   num: 45,
    //   text: "Were there any additional tactics that your affected business used that did not clearly fit into one of the tactic categories listed?",
    //   dependency: "isQuestion22Ready",
    //   afterInput: "Question22",
    //   optionsType: "select",
    //   options: [
    //     {
    //       value: "yes",
    //       label: "Yes",
    //     },
    //     {
    //       value: "no",
    //       label: "No",
    //     },
    //   ],
    // },

    // {
    //   _id: "Question23",
    //   num: 46,
    //   text: "Please, provide a short description of the tactic your business used. Keep in mind, though, that you will not be able to see metrics or any statistical information for a tactic other than the ones visualized previously",
    //   dependency: "isQuestion23Ready",
    //   optionsType: "string",
    //   afterInput: "Question23",
    // },

    {
      _id: "Question24",
      num: 47,
      description:
        "Please indicate whether the implementation of each of the tactics you just identified incurred a cost or saved the company money.",
      text: 'In most cases, tactics incur a cost to implement. However, in some cases they can incur a cost-savings (negative cost) such as in the case of efficiency measures. It is important that you not confuse cost savings implementing a tactic with sales revenue losses that were avoided by using the tactic (see cost guidance <svg aria-hidden="true" style="width: 12px; display: inline-block; vertical-align: middle;" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="help-icon svg-inline--fa fa-info-circle fa-w-16"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" class=""></path></svg> below).<br> <span class="fakep">Additionally, in some rare cases, the implementation of a tactic can be a break-even (zero cost). If that applies to you for any tactic your business used, please select "incurred a cost" and select the lowest dollar value. Do Not apply zero cost inputs to cost savings.</span>',
      dependency: "isQuestion24Ready",
      optionsType: "radiogroup",
      // afterInput: "Question24",
      options: [],
    },

    {
      _id: "Question25",
      num: 48,
      text:
        // "By using the tactics you just identified, your affected business
        // avoided some potential losses in Sales Revenue. Please provide your
        // best estimate of the Sales Revenue your business would have lost
        // during its recovery period HAD YOU NOT USED each of those tactics.",
        "By using the tactics listed above, your affected business avoided some potential losses in Sales Revenue. Beyond the _total_sales_revenue_losses_ you reported losing during your recovery period, please provide your best estimate of the additional Sales Revenue your business would have lost during your recovery period HAD YOU NOT USED each of those tactics. In other words, how much more would you have lost if you had not used these tactics?",
      dependency: "isQuestion25Ready",
      afterInput: "Question25",
      optionsType: "select",
      options: [
        {
          value: "Less than $5,000",
          label: "Less than $5,000",
          questions: [
            {
              _id: "Question25a",
              num: 49,
              text: "And <em>more specifically</em>, which range below is closest to the additional sales revenue you would have lost (in dollars) HAD YOU NOT USED these tactics.",
              dependency: "#Question25",
              afterInput: "Question25a",
              optionsType: "select",
              options: [
                {
                  value: "$500 to less than $1,000",
                  label: "$500 to less than $1,000",
                  reportedValue: 750,
                },
                {
                  value: "$1,000 to less than $1,500",
                  label: "$1,000 to less than $1,500",
                  reportedValue: 1250,
                },
                {
                  value: "$1,500 to less than $2,000",
                  label: "$1,500 to less than $2,000",
                  reportedValue: 1750,
                },
                {
                  value: "$2,000 to less than $2,500",
                  label: "$2,000 to less than $2,500",
                  reportedValue: 2250,
                },
                {
                  value: "$2,500 to less than $3,000",
                  label: "$2,500 to less than $3,000",
                  reportedValue: 2750,
                },
                {
                  value: "$3,000 to less than $3,500",
                  label: "$3,000 to less than $3,500",
                  reportedValue: 3250,
                },
                {
                  value: "$3,500 to less than $4,000",
                  label: "$3,500 to less than $4,000",
                  reportedValue: 3750,
                },
                {
                  value: "$4,000 to less than $4,500",
                  label: "$4,000 to less than $4,500",
                  reportedValue: 4250,
                },
                {
                  value: "$4,500 to less than $5,000",
                  label: "$4,500 to less than $5,000",
                  reportedValue: 4750,
                },
              ],
            },
          ],
        },
        {
          value: "$5,000 to less than $10,000",
          label: "$5,000 to less than $10,000",
          questions: [
            {
              _id: "Question25a",
              num: 49,
              text: "And <em>more specifically</em>, which range below is closest to the additional sales revenue you would have lost (in dollars) HAD YOU NOT USED these tactics.",
              dependency: "#Question25",
              afterInput: "Question25a",
              optionsType: "select",
              options: [
                {
                  value: "$5,000 to less than $5,500",
                  label: "$5,000 to less than $5,500",
                  reportedValue: 5250,
                },
                {
                  value: "$5,500 to less than $6,000",
                  label: "$5,500 to less than $6,000",
                  reportedValue: 5750,
                },
                {
                  value: "$6,000 to less than $6,500",
                  label: "$6,000 to less than $6,500",
                  reportedValue: 6250,
                },
                {
                  value: "$6,500 to less than $7,000",
                  label: "$6,500 to less than $7,000",
                  reportedValue: 6750,
                },
                {
                  value: "$7,000 to less than $7,500",
                  label: "$7,000 to less than $7,500",
                  reportedValue: 7250,
                },
                {
                  value: "$7,500 to less than $8,000",
                  label: "$7,500 to less than $8,000",
                  reportedValue: 7750,
                },
                {
                  value: "$8,000 to less than $8,500",
                  label: "$8,000 to less than $8,500",
                  reportedValue: 8250,
                },
                {
                  value: "$8,500 to less than $9,000",
                  label: "$8,500 to less than $9,000",
                  reportedValue: 8750,
                },
                {
                  value: "$9,000 to less than $9,500",
                  label: "$9,000 to less than $9,500",
                  reportedValue: 8250,
                },
                {
                  value: "$9,500 to less than $10,000",
                  label: "$9,500 to less than $10,000",
                  reportedValue: 9750,
                },
              ],
            },
          ],
        },
        {
          value: "$10,000 to less than $25,000",
          label: "$10,000 to less than $25,000",
          questions: [
            {
              _id: "Question25a",
              num: 49,
              text: "And <em>more specifically</em>, which range below is closest to the additional sales revenue you would have lost (in dollars) HAD YOU NOT USED these tactics.",
              dependency: "#Question25",
              afterInput: "Question25a",
              optionsType: "select",
              options: [
                {
                  value: "$10,000 to less than $11,000",
                  label: "$10,000 to less than $11,000",
                  reportedValue: 10500,
                },
                {
                  value: "$11,000 to less than $12,000",
                  label: "$11,000 to less than $12,000",
                  reportedValue: 11500,
                },
                {
                  value: "$12,000 to less than $13,000",
                  label: "$12,000 to less than $13,000",
                  reportedValue: 12500,
                },
                {
                  value: "$13,000 to less than $14,000",
                  label: "$13,000 to less than $14,000",
                  reportedValue: 13500,
                },
                {
                  value: "$14,000 to less than $15,000",
                  label: "$14,000 to less than $15,000",
                  reportedValue: 14500,
                },
                {
                  value: "$15,000 to less than $16,000",
                  label: "$15,000 to less than $16,000",
                  reportedValue: 15500,
                },
                {
                  value: "$16,000 to less than $17,000",
                  label: "$16,000 to less than $17,000",
                  reportedValue: 16500,
                },
                {
                  value: "$17,000 to less than $18,000",
                  label: "$17,000 to less than $18,000",
                  reportedValue: 17500,
                },
                {
                  value: "$18,000 to less than $19,000",
                  label: "$18,000 to less than $19,000",
                  reportedValue: 18500,
                },
                {
                  value: "$19,000 to less than $20,000",
                  label: "$19,000 to less than $20,000",
                  reportedValue: 19500,
                },
                {
                  value: "$20,000 to less than $21,000",
                  label: "$20,000 to less than $21,000",
                  reportedValue: 20500,
                },
                {
                  value: "$21,000 to less than $22,000",
                  label: "$21,000 to less than $22,000",
                  reportedValue: 21500,
                },
                {
                  value: "$22,000 to less than $23,000",
                  label: "$22,000 to less than $23,000",
                  reportedValue: 22500,
                },
                {
                  value: "$23,000 to less than $24,000",
                  label: "$23,000 to less than $24,000",
                  reportedValue: 23500,
                },
                {
                  value: "$24,000 to less than $25,000",
                  label: "$24,000 to less than $25,000",
                  reportedValue: 24500,
                },
              ],
            },
          ],
        },
        {
          value: "$25,000 to less than $50,000",
          label: "$25,000 to less than $50,000",
          questions: [
            {
              _id: "Question25a",
              num: 49,
              text: "And <em>more specifically</em>, which range below is closest to the additional sales revenue you would have lost (in dollars) HAD YOU NOT USED these tactics.",
              dependency: "#Question25",
              afterInput: "Question25a",
              optionsType: "select",
              options: [
                {
                  value: "$25,000 to less than $27,500",
                  label: "$25,000 to less than $27,500",
                  reportedValue: 26250,
                },
                {
                  value: "$27,500 to less than $30,000",
                  label: "$27,500 to less than $30,000",
                  reportedValue: 28750,
                },
                {
                  value: "$30,000 to less than $32,500",
                  label: "$30,000 to less than $32,500",
                  reportedValue: 31250,
                },
                {
                  value: "$32,500 to less than $35,000",
                  label: "$32,500 to less than $35,000",
                  reportedValue: 33750,
                },
                {
                  value: "$35,000 to less than $37,500",
                  label: "$35,000 to less than $37,500",
                  reportedValue: 36250,
                },
                {
                  value: "$37,500 to less than $40,000",
                  label: "$37,500 to less than $40,000",
                  reportedValue: 38750,
                },
                {
                  value: "$40,000 to less than $42,500",
                  label: "$40,000 to less than $42,500",
                  reportedValue: 41250,
                },
                {
                  value: "$42,500 to less than $45,000",
                  label: "$42,500 to less than $45,000",
                  reportedValue: 43750,
                },
                {
                  value: "$45,000 to less than $47,500",
                  label: "$45,000 to less than $47,500",
                  reportedValue: 46250,
                },
                {
                  value: "$47,500 to less than $50,000",
                  label: "$47,500 to less than $50,000",
                  reportedValue: 48750,
                },
              ],
            },
          ],
        },
        {
          value: "$50,000 to less than $75,000",
          label: "$50,000 to less than $75,000",
          questions: [
            {
              _id: "Question25a",
              num: 49,
              text: "And <em>more specifically</em>, which range below is closest to the additional sales revenue you would have lost (in dollars) HAD YOU NOT USED these tactics.",
              dependency: "#Question25",
              afterInput: "Question25a",
              optionsType: "select",
              options: [
                {
                  value: "$50,000 to less than $52,500",
                  label: "$50,000 to less than $52,500",
                  reportedValue: 51250,
                },
                {
                  value: "$52,500 to less than $55,000",
                  label: "$52,500 to less than $55,000",
                  reportedValue: 53750,
                },
                {
                  value: "$55,000 to less than $57,500",
                  label: "$55,000 to less than $57,500",
                  reportedValue: 56250,
                },
                {
                  value: "$57,500 to less than $60,000",
                  label: "$57,500 to less than $60,000",
                  reportedValue: 58750,
                },
                {
                  value: "$60,000 to less than $62,500",
                  label: "$60,000 to less than $62,500",
                  reportedValue: 61250,
                },
                {
                  value: "$62,500 to less than $65,000",
                  label: "$62,500 to less than $65,000",
                  reportedValue: 63750,
                },
                {
                  value: "$65,000 to less than $67,500",
                  label: "$65,000 to less than $67,500",
                  reportedValue: 66250,
                },
                {
                  value: "$67,500 to less than $70,000",
                  label: "$67,500 to less than $70,000",
                  reportedValue: 68750,
                },
                {
                  value: "$70,000 to less than $72,500",
                  label: "$70,000 to less than $72,500",
                  reportedValue: 71250,
                },
                {
                  value: "$72,500 to less than $75,000",
                  label: "$72,500 to less than $75,000",
                  reportedValue: 73750,
                },
              ],
            },
          ],
        },
        {
          value: "$75,000 to less than $100,000",
          label: "$75,000 to less than $100,000",
          questions: [
            {
              _id: "Question25a",
              num: 49,
              text: "And <em>more specifically</em>, which range below is closest to the additional sales revenue you would have lost (in dollars) HAD YOU NOT USED these tactics.",
              dependency: "#Question25",
              afterInput: "Question25a",
              optionsType: "select",
              options: [
                {
                  value: "$75,000 to less than $77,500",
                  label: "$75,000 to less than $77,500",
                  reportedValue: 75250,
                },
                {
                  value: "$77,500 to less than $80,000",
                  label: "$77,500 to less than $80,000",
                  reportedValue: 78750,
                },
                {
                  value: "$80,000 to less than $82,500",
                  label: "$80,000 to less than $82,500",
                  reportedValue: 81250,
                },
                {
                  value: "$82,500 to less than $85,000",
                  label: "$82,500 to less than $85,000",
                  reportedValue: 83750,
                },
                {
                  value: "$85,000 to less than $87,500",
                  label: "$85,000 to less than $87,500",
                  reportedValue: 86250,
                },
                {
                  value: "$87,500 to less than $90,000",
                  label: "$87,500 to less than $90,000",
                  reportedValue: 88750,
                },
                {
                  value: "$90,000 to less than $92,500",
                  label: "$90,000 to less than $92,500",
                  reportedValue: 91250,
                },
                {
                  value: "$92,500 to less than $95,000",
                  label: "$92,500 to less than $95,000",
                  reportedValue: 93750,
                },
                {
                  value: "$95,000 to less than $97,500",
                  label: "$95,000 to less than $97,500",
                  reportedValue: 96250,
                },
                {
                  value: "$97,500 to less than $100,000",
                  label: "$97,500 to less than $100,000",
                  reportedValue: 98750,
                },
              ],
            },
          ],
        },
        {
          value: "$100,000 to less than $200,000",
          label: "$100,000 to less than $200,000",
          questions: [
            {
              _id: "Question25a",
              num: 49,
              text: "And <em>more specifically</em>, which range below is closest to the additional sales revenue you would have lost (in dollars) HAD YOU NOT USED these tactics.",
              dependency: "#Question25",
              afterInput: "Question25a",
              optionsType: "select",
              options: [
                {
                  value: "$100,000 to less than $110,000",
                  label: "$100,000 to less than $110,000",
                  reportedValue: 105000,
                },
                {
                  value: "$110,000 to less than $120,000",
                  label: "$110,000 to less than $120,000",
                  reportedValue: 115000,
                },
                {
                  value: "$120,000 to less than $130,000",
                  label: "$120,000 to less than $130,000",
                  reportedValue: 125000,
                },
                {
                  value: "$130,000 to less than $140,000",
                  label: "$130,000 to less than $140,000",
                  reportedValue: 135000,
                },
                {
                  value: "$140,000 to less than $150,000",
                  label: "$140,000 to less than $150,000",
                  reportedValue: 145000,
                },
                {
                  value: "$150,000 to less than $160,000",
                  label: "$150,000 to less than $160,000",
                  reportedValue: 155000,
                },
                {
                  value: "$160,000 to less than $170,000",
                  label: "$160,000 to less than $170,000",
                  reportedValue: 165000,
                },
                {
                  value: "$170,000 to less than $180,000",
                  label: "$170,000 to less than $180,000",
                  reportedValue: 175000,
                },
                {
                  value: "$180,000 to less than $190,000",
                  label: "$180,000 to less than $190,000",
                  reportedValue: 185000,
                },
                {
                  value: "$190,000 to less than $200,000",
                  label: "$190,000 to less than $200,000",
                  reportedValue: 195000,
                },
              ],
            },
          ],
        },
        {
          value: "$200,000 to less than $300,000",
          label: "$200,000 to less than $300,000",
          questions: [
            {
              _id: "Question25a",
              num: 49,
              text: "And <em>more specifically</em>, which range below is closest to the additional sales revenue you would have lost (in dollars) HAD YOU NOT USED these tactics.",
              dependency: "#Question25a",
              afterInput: "Question25a",
              optionsType: "select",
              options: [
                {
                  value: "$200,000 to less than $210,000",
                  label: "$200,000 to less than $210,000",
                  reportedValue: 205000,
                },
                {
                  value: "$210,000 to less than $220,000",
                  label: "$210,000 to less than $220,000",
                  reportedValue: 215000,
                },
                {
                  value: "$220,000 to less than $230,000",
                  label: "$220,000 to less than $230,000",
                  reportedValue: 225000,
                },
                {
                  value: "$230,000 to less than $240,000",
                  label: "$230,000 to less than $240,000",
                  reportedValue: 235000,
                },
                {
                  value: "$240,000 to less than $250,000",
                  label: "$240,000 to less than $250,000",
                  reportedValue: 245000,
                },
                {
                  value: "$250,000 to less than $260,000",
                  label: "$250,000 to less than $260,000",
                  reportedValue: 255000,
                },
                {
                  value: "$260,000 to less than $270,000",
                  label: "$260,000 to less than $270,000",
                  reportedValue: 265000,
                },
                {
                  value: "$270,000 to less than $280,000",
                  label: "$270,000 to less than $280,000",
                  reportedValue: 275000,
                },
                {
                  value: "$280,000 to less than $290,000",
                  label: "$280,000 to less than $290,000",
                  reportedValue: 285000,
                },
                {
                  value: "$290,000 to less than $300,000",
                  label: "$290,000 to less than $300,000",
                  reportedValue: 295000,
                },
              ],
            },
          ],
        },
        {
          value: "$300,000 to less than $400,000",
          label: "$300,000 to less than $400,000",
          questions: [
            {
              _id: "Question25a",
              num: 49,
              text: "And <em>more specifically</em>, which range below is closest to the additional sales revenue you would have lost (in dollars) HAD YOU NOT USED these tactics.",
              dependency: "#Question25",
              afterInput: "Question25a",
              optionsType: "select",
              options: [
                {
                  value: "$300,000 to less than $310,000",
                  label: "$300,000 to less than $310,000",
                  reportedValue: 305000,
                },
                {
                  value: "$310,000 to less than $320,000",
                  label: "$310,000 to less than $320,000",
                  reportedValue: 315000,
                },
                {
                  value: "$320,000 to less than $330,000",
                  label: "$320,000 to less than $330,000",
                  reportedValue: 325000,
                },
                {
                  value: "$330,000 to less than $340,000",
                  label: "$330,000 to less than $340,000",
                  reportedValue: 335000,
                },
                {
                  value: "$340,000 to less than $350,000",
                  label: "$340,000 to less than $350,000",
                  reportedValue: 345000,
                },
                {
                  value: "$350,000 to less than $360,000",
                  label: "$350,000 to less than $360,000",
                  reportedValue: 355000,
                },
                {
                  value: "$360,000 to less than $370,000",
                  label: "$360,000 to less than $370,000",
                  reportedValue: 365000,
                },
                {
                  value: "$370,000 to less than $380,000",
                  label: "$370,000 to less than $380,000",
                  reportedValue: 375000,
                },
                {
                  value: "$380,000 to less than $390,000",
                  label: "$380,000 to less than $390,000",
                  reportedValue: 385000,
                },
                {
                  value: "$390,000 to less than $400,000",
                  label: "$390,000 to less than $400,000",
                  reportedValue: 395000,
                },
              ],
            },
          ],
        },
        {
          value: "$400,000 to less than $500,000",
          label: "$400,000 to less than $500,000",
          questions: [
            {
              _id: "Question25a",
              num: 49,
              text: "And <em>more specifically</em>, which range below is closest to the additional sales revenue you would have lost (in dollars) HAD YOU NOT USED these tactics.",
              dependency: "#Question25",
              afterInput: "Question25a",
              optionsType: "select",
              options: [
                {
                  value: "$400,000 to less than $410,000",
                  label: "$400,000 to less than $410,000",
                  reportedValue: 405000,
                },
                {
                  value: "$410,000 to less than $420,000",
                  label: "$410,000 to less than $420,000",
                  reportedValue: 415000,
                },
                {
                  value: "$420,000 to less than $430,000",
                  label: "$420,000 to less than $430,000",
                  reportedValue: 425000,
                },
                {
                  value: "$430,000 to less than $440,000",
                  label: "$430,000 to less than $440,000",
                  reportedValue: 435000,
                },
                {
                  value: "$440,000 to less than $450,000",
                  label: "$440,000 to less than $450,000",
                  reportedValue: 445000,
                },
                {
                  value: "$450,000 to less than $460,000",
                  label: "$450,000 to less than $460,000",
                  reportedValue: 455000,
                },
                {
                  value: "$460,000 to less than $470,000",
                  label: "$460,000 to less than $470,000",
                  reportedValue: 465000,
                },
                {
                  value: "$470,000 to less than $480,000",
                  label: "$470,000 to less than $480,000",
                  reportedValue: 475000,
                },
                {
                  value: "$480,000 to less than $490,000",
                  label: "$480,000 to less than $490,000",
                  reportedValue: 485000,
                },
                {
                  value: "$490,000 to less than $500,000",
                  label: "$490,000 to less than $500,000",
                  reportedValue: 495000,
                },
              ],
            },
          ],
        },
        {
          value: "$500,000 to less than $1 million",
          label: "$500,000 to less than $1 million",
          questions: [
            {
              _id: "Question25a",
              num: 49,
              text: "And <em>more specifically</em>, which range below is closest to the additional sales revenue you would have lost (in dollars) HAD YOU NOT USED these tactics.",
              dependency: "#Question25",
              afterInput: "Question25a",
              optionsType: "select",
              options: [
                {
                  value: "$500,000 to less than $550,000",
                  label: "$500,000 to less than $550,000",
                  reportedValue: 525000,
                },
                {
                  value: "$550,000 to less than $600,000",
                  label: "$550,000 to less than $600,000",
                  reportedValue: 575000,
                },
                {
                  value: "$600,000 to less than $650,000",
                  label: "$600,000 to less than $650,000",
                  reportedValue: 625000,
                },
                {
                  value: "$650,000 to less than $700,000",
                  label: "$650,000 to less than $700,000",
                  reportedValue: 675000,
                },
                {
                  value: "$700,000 to less than $750,000",
                  label: "$700,000 to less than $750,000",
                  reportedValue: 725000,
                },
                {
                  value: "$750,000 to less than $800,000",
                  label: "$750,000 to less than $800,000",
                  reportedValue: 775000,
                },
                {
                  value: "$800,000 to less than $850,000",
                  label: "$800,000 to less than $850,000",
                  reportedValue: 825000,
                },
                {
                  value: "$850,000 to less than $900,000",
                  label: "$850,000 to less than $900,000",
                  reportedValue: 875000,
                },
                {
                  value: "$900,000 to less than $950,000",
                  label: "$900,000 to less than $950,000",
                  reportedValue: 925000,
                },
                {
                  value: "$950,000 to less than $1 million",
                  label: "$950,000 to less than $1 million",
                  reportedValue: 975000,
                },
              ],
            },
          ],
        },
        {
          value: "$1 million to less than $10 million",
          label: "$1 million to less than $10 million",
          questions: [
            {
              _id: "Question25a",
              num: 49,
              text: "And <em>more specifically</em>, which range below is closest to the additional sales revenue you would have lost (in dollars) HAD YOU NOT USED these tactics.",
              dependency: "#Question25",
              afterInput: "Question25a",
              optionsType: "select",
              options: [
                {
                  value: "$1 million to less than $2 million",
                  label: "$1 million to less than $2 million",
                  reportedValue: 1500000,
                },
                {
                  value: "$2 million to less than $3 million",
                  label: "$2 million to less than $3 million",
                  reportedValue: 2500000,
                },
                {
                  value: "$3 million to less than $4 million",
                  label: "$3 million to less than $4 million",
                  reportedValue: 3500000,
                },
                {
                  value: "$4 million to less than $5 million",
                  label: "$4 million to less than $5 million",
                  reportedValue: 4500000,
                },
                {
                  value: "$5 million to less than $6 million",
                  label: "$5 million to less than $6 million",
                  reportedValue: 5500000,
                },
                {
                  value: "$6 million to less than $7 million",
                  label: "$6 million to less than $7 million",
                  reportedValue: 6500000,
                },
                {
                  value: "$7 million to less than $8 million",
                  label: "$7 million to less than $8 million",
                  reportedValue: 7500000,
                },
                {
                  value: "$8 million to less than $9 million",
                  label: "$8 million to less than $9 million",
                  reportedValue: 8500000,
                },
                {
                  value: "$9 million to less than $10 million",
                  label: "$9 million to less than $10 million",
                  reportedValue: 9500000,
                },
              ],
            },
          ],
        },
        {
          value: "$10 million to less than $100 million",
          label: "$10 million to less than $100 million",
          questions: [
            {
              _id: "Question25a",
              num: 49,
              text: "And <em>more specifically</em>, which range below is closest to the additional sales revenue you would have lost (in dollars) HAD YOU NOT USED these tactics.",
              dependency: "#Question25",
              afterInput: "Question25a",
              optionsType: "select",
              options: [
                {
                  value: "$10 million to less than $20 million",
                  label: "$10 million to less than $20 million",
                  reportedValue: 15000000,
                },
                {
                  value: "$20 million to less than $30 million",
                  label: "$20 million to less than $30 million",
                  reportedValue: 25000000,
                },
                {
                  value: "$30 million to less than $40 million",
                  label: "$30 million to less than $40 million",
                  reportedValue: 35000000,
                },
                {
                  value: "$40 million to less than $50 million",
                  label: "$40 million to less than $50 million",
                  reportedValue: 45000000,
                },
                {
                  value: "$50 million to less than $60 million",
                  label: "$50 million to less than $60 million",
                  reportedValue: 55000000,
                },
                {
                  value: "$60 million to less than $70 million",
                  label: "$60 million to less than $70 million",
                  reportedValue: 65000000,
                },
                {
                  value: "$70 million to less than $80 million",
                  label: "$70 million to less than $80 million",
                  reportedValue: 75000000,
                },
                {
                  value: "$80 million to less than $90 million",
                  label: "$80 million to less than $90 million",
                  reportedValue: 85000000,
                },
                {
                  value: "$90 million to less than $100 million",
                  label: "$90 million to less than $100 million",
                  reportedValue: 95000000,
                },
              ],
            },
          ],
        },
        {
          value: "$100 million or more",
          label: "$100 million or more",
          questions: [
            {
              _id: "Question25a",
              num: 49,
              text: "And <em>more specifically</em>, which range below is closest to the additional sales revenue you would have lost (in dollars) HAD YOU NOT USED these tactics.",
              dependency: "#Question25",
              afterInput: "Question25a",
              optionsType: "select",
              options: [
                {
                  value: "$100 million to less than $200 million",
                  label: "$100 million to less than $200 million",
                  reportedValue: 150000000,
                },
                {
                  value: "$200 million to less than $300 million",
                  label: "$200 million to less than $300 million",
                  reportedValue: 250000000,
                },
                {
                  value: "$300 million to less than $400 million",
                  label: "$300 million to less than $400 million",
                  reportedValue: 350000000,
                },
                {
                  value: "$400 million to less than $500 million",
                  label: "$400 million to less than $500 million",
                  reportedValue: 450000000,
                },
                {
                  value: "$500 million to less than $600 million",
                  label: "$500 million to less than $600 million",
                  reportedValue: 550000000,
                },
                {
                  value: "$600 million to less than $700 million",
                  label: "$600 million to less than $700 million",
                  reportedValue: 650000000,
                },
                {
                  value: "$700 million to less than $800 million",
                  label: "$700 million to less than $800 million",
                  reportedValue: 750000000,
                },
                {
                  value: "$800 million to less than $900 million",
                  label: "$800 million to less than $900 million",
                  reportedValue: 850000000,
                },
                {
                  value: "$900 million or more",
                  label: "$900 million or more",
                  reportedValue: 950000000,
                },
              ],
            },
          ],
        },
        // {
        //   value: "Don't know",
        //   label: "Don't know",
        // },
      ],
      substitutedValues: [
        {
          name: "_total_sales_revenue_losses_", // Required, string to replace
          source: '["#Question16a", "totalRevenueLostDuringTheDisaster"]', // Required, question source to use
          format: "currency", // Optional
        },
      ],
    },

    {
      _id: "Question26",
      num: 50,
      text: "You have reported a value for sales revenue losses that exceeds your total sales revenue losses for your entire recovery period. Do you think this is due to:",
      dependency: "isQuestion26Ready",
      afterInput: "Question26",
      optionsType: "select",
      options: [
        {
          value:
            "An error in your reporting of expected sales revenues had there been no disaster",
          label:
            "An error in your reporting of expected sales revenues had there been no disaster",
        },
        {
          value:
            "An error in your reporting of total sales revenues lost over the duration of your recovery period",
          label:
            "An error in your reporting of total sales revenues lost over the duration of your recovery period",
        },
        {
          value:
            "An error in your reporting of additional sales revenue losses your business would have lost had you not used any tactics",
          label:
            "An error in your reporting of additional sales revenue losses your business would have lost had you not used any tactics",
        },
      ],
    },

    {
      _id: "Question27",
      num: 51,
      text: "You indicated that your affected business avoided about lost_sales_revenue of lost Sales Revenue by using the selected resilience tactics. Please provide your best estimate of the percentage of the lost_sales_revenue that was due to each tactic. (The total should equal 100%).",
      dependency: "isQuestion27Ready",
      afterInput: "Question27",
      optionsType: "slidergroup",
      options: [],
      substitutedValues: [
        {
          name: "lost_sales_revenue", // Required, string to replace
          source: "#Question25a", // Required, question source to use
          value: "reportedValue",
          format: "currency", // Optional
        },
      ],
    },
  ],
});

/*
  MUTATIONS
 */
export const mutations = {
  setHasSurvey(state, value) {
    state.hasSurvey = value;
  },
  setLastSurvey(state, lastSurvey) {
    state.lastSurvey = lastSurvey;
  },
  setCalculatorUrls(state, input) {
    state.calculatorUrls = input;
  },
  setResultUrls(state, input) {
    state.resultUrls = input;
  },
  setDynamicDialogComponentValue(state, input) {
    state.dynamicDialogComponentValue = input;
  },
  incrementQuestion(state, input) {
    if (input.id) {
      state.questionCounts[input.id] = state.questionCounts[input.id] + 1;
    }
  },
  setSliderGroupValue(state, input) {
    const answer = state.formData[input.questionId]?.answer;
    if (!answer) {
      state.formData[input.questionId].answer = [input.newValue];
    } else {
      const existingValue = answer.find(i => i.id === input.newValue.id);
      if (existingValue) {
        const idx = answer.indexOf(existingValue);
        // answer.splice(idx, 1, input.newValue);
        Vue.delete(answer, idx);
      }
      // answer.splice(0, 0, input.newValue);
      if (input.newValue) {
        Vue.set(answer, answer.length, input.newValue);
      }
    }
  },
  populateFormDataFromQuestions(state) {
    const keys = state.questions.map(q => q._id);
    keys.forEach(id => (state.formData[id] = null));
    const d = new Date();
    state.formData.Question1 = {
      questionId: "Question1",
      answer: null,
      createdAt: d,
      timezoneOffset: -d.getTimezoneOffset(),
    };
  },
  setupQuestionStore(state, value) {
    const d = new Date();
    const answer =
      value[value.id]?.answer ?? state.formData[value.id]?.answer ?? null;
    const newResponse = {
      questionId: value.id,
      answer: answer,
      reportedValue: null,
      num: value[value.id].num,
      createdAt: d,
      timezoneOffset: -d.getTimezoneOffset(),
    };
    // NOTE: Replace the whole formData object so all properties are reactive
    const newFormData = { ...state.formData };
    newFormData[value.id] = newResponse;
    state.formData = newFormData;
  },
  setFormData(state, value) {
    state.formData = value;
  },
  setQuestionCounts(state, value) {
    state.questionCounts = value;
  },
  setCalculations(state, value) {
    state.calculations = value;
  },
  setAnswer(state, value) {
    const questionResponse = state.formData[`${value.id}`];
    if (questionResponse) {
      const currentAnswer = JSON.stringify(
        state.formData[`${value.id}`].answer
      );
      if (currentAnswer !== JSON.stringify(value.answer)) {
        state.formData[`${value.id}`].answer = value.answer;
        if (value.num) {
          state.formData[`${value.id}`].num = value.num;
        }
      }
      if (value.hasOwnProperty("reportedValue")) {
        const currentReportedValue = JSON.stringify(
          state.formData[`${value.id}`].reportedValue
        );
        if (currentReportedValue !== JSON.stringify(value.reported)) {
          state.formData[`${value.id}`].reportedValue = value.reportedValue;
        }
      }
    }
  },
  setReportedValue(state, value) {
    const questionResponse = state.formData[`${value.id}`];
    if (questionResponse) {
      Vue.set(
        state.formData[`${value.id}`],
        "reportedValue",
        value.reportedValue
      );
    }
  },
  clearAnswer(state, questionId) {
    // NOTE: 7/30/21 Clear the answer
    const questionValue = state.formData[`${questionId}`];
    if (questionValue && typeof questionValue === "object") {
      questionValue.answer = null;
      questionValue.reportedValue = null;
    }
    // NOTE: 7/30/21 Remove the response from the store to kill reactive operations
    Vue.delete(state.formData, questionId);
  },
  clearAnswerAndChildren(state, questionId) {
    const questionValue = state.formData[`${questionId}`];
    if (questionValue) {
      questionValue.answer = null;
      if (questionValue.reportedValue) {
        questionValue.reportedValue.answer = null;
      }
    }
    const numberMatches = questionId.match(/\d+/);
    if (numberMatches) {
      const number = numberMatches[0];
      const keys = Object.keys(state.formData).filter(k =>
        k.match(new RegExp(`Question${number}\\w`))
      );
      keys.forEach(k => {
        state.formData[k].answer = null;
        if (state.formData[k].reportedValue) {
          state.formData[k].reportedValue = null;
        }
      });
    }
  },
  setQuestionScratch(state, value) {
    Vue.set(state.questionScratch, value.id, value.value);
  },
  setQuestionMessage(state, value) {
    Vue.set(state.messages, value.id, value.message);
  },
  setQuestionOptionValue(state, value) {
    const question = state.formData[`${value.questionId}`];
    if (question) {
      Vue.set(question, "optionValue", value.optionValue);
    }
  },
  removeQuestion(state, value) {
    Vue.delete(state.formData, value);
  },
  setTerminatedBy(state, value) {
    state.terminated = value;
  },
  setTerminatedResponse(state, value) {
    state.terminatedResponse = value;
  },
  clearTerminated(state) {
    state.terminated = null;
    state.terminatedResponse = null;
  },
  setDialogCancel(state, value) {
    state.dialogCancel = value;
  },
  setDialogConfirm(state, value) {
    state.dialogConfirm = value;
  },
  toggleQuestionDialogVisible(state) {
    state.questionDialogVisible = !state.questionDialogVisible;
  },
  setQuestionDialogVisible(state) {
    state.questionDialogVisible = true;
  },
  setQuestionDialogTitle(state, value) {
    state.questionDialogTitle = value;
  },
  setQuestionInformation(state, value) {
    state.questionInformation = value;
  },
  setQuestionDialogConfirm(state, cb) {
    state.questionDialogConfirm = cb;
  },
  setShowDynamicComponent(state, value) {
    state.showDynamicComponent = value;
  },
  setDone(state, value) {
    state.done = value;
  },
  setComplete(state, value) {
    state.complete = value;
  },
  setRecoveryStatus(state, value) {
    state.recoveryStatus = value;
  },
  resetFormData(state) {
    state.formData = {
      Question1: {
        questionId: "Question1",
        answer: undefined,
        createdAt: new Date(),
        timezoneOffset: -new Date().getTimezoneOffset(),
      },
    };
    state.calculations = [];
    state.terminated = null;
    state.terminatedResponse = null;
  },
  setDynamicProperty(state, input) {
    Vue.set(state, input.name, input.value);
  },
  removeDynamicProperty(state, name) {
    state[name] = null;
  },
  setTotalRevenueLostDuringTheDisaster(state, value) {
    state.formData.totalRevenueLostDuringTheDisaster = value;
  },
  setQuestion24Options(state, value) {
    const question24 = state.questions.find(q => q._id === "Question24");
    if (value) {
      const options = Object.assign({}, value);
      let newOptions = value;
      const question21 = state.questions.find(q => q._id === "Question21");
      const indexes = question21.options.reduce((acc, val, idx) => {
        acc[val.value] = idx;
        return acc;
      }, {});
      // // indexes: { "TECHNOLOGICAL CHANGE": 8 }
      // // value: [{label: "...", value: "TECHNOLOGICAL CHANGE"}]
      newOptions = value
        .filter(o => o.value !== "NONE")
        .map(o => {
          const idx = indexes[`${o.value}`];
          return {
            idx: idx,
            option: o,
          };
        })
        .sort((a, b) => {
          if (a.idx > a.idx) return 1;
          if (a.idx < b.idx) return -1;
          return 0;
        })
        .map(o => {
          o.option.help = true;
          return o.option;
        });
      newOptions.forEach((o, idx) => {
        const num = Number(`46${idx}`);
        o.questions = [
          {
            _id: `Question24-${o.value}`,
            num: num,
            child: true,
            dependency: "#Question24",
            optionsType: "number",
            maxNumber: 100000000,
            text: "How much did the implementation of _name_ _action_ the company?",
            substitutedValues: [
              {
                name: "_name_",
                value: `<strong data-id="Question24-${
                  o.value
                }" class="help">${o.value.toLowerCase()}</strong>`,
                format: "lowercase",
              },
              {
                name: "_action_",
                source: `Question24`,
                format: "lowercase",
              },
            ],
            renderOptions: "medium",
          },
        ];
      });
      question24.options = newOptions;
      const answer = state.formData.Question24?.answer;
      const newAnswer = [];
      if (answer !== null && answer !== undefined && answer !== []) {
        value.forEach(vObj => {
          const v = answer.find(a => a.id === vObj.value);
          if (v) {
            newAnswer.push(v);
          } else {
            newAnswer.push({
              id: vObj.value,
              value: "Cost",
            });
          }
        });
      } else {
        state.formData.Question24 = {
          questionId: "Question24",
          answer: undefined,
          num: 47,
          createdAt: new Date(),
          timezoneOffset: -new Date().getTimezoneOffset(),
        };
        Object.values(options).forEach(option => {
          const opt = {
            id: option.value,
            value: "Cost",
          };
          newAnswer.push(opt);
        });
      }
      state.formData.Question24.answer = newAnswer;
    }
  },
  setQuestion27Options(state, value) {
    const question21 = state.questions.find(q => q._id === "Question21");
    const question27 = state.questions.find(q => q._id === "Question27");

    if (value) {
      let newOptions = value;
      const indexes = question21.options.reduce((acc, val, idx) => {
        acc[val.value] = idx;
        return acc;
      }, {});
      // // indexes: { "TECHNOLOGICAL CHANGE": 8 }
      // // value: [{label: "...", value: "TECHNOLOGICAL CHANGE"}]
      newOptions = value
        .filter(o => o.value !== "NONE")
        .map(o => {
          const idx = indexes[`${o.value}`];
          return {
            idx: idx,
            option: o,
          };
        })
        .sort((a, b) => {
          if (a.idx > a.idx) return 1;
          if (a.idx < b.idx) return -1;
          return 0;
        })
        .map(o => o.option);
      question27.options = [...newOptions];

      if (state.formData.Question27) {
        const answer = state.formData.Question27?.answer;
        const newAnswer = [];
        if (answer !== null && answer !== undefined && answer !== []) {
          value.forEach(vObj => {
            const v = answer.find(a => a.id === vObj.value);
            if (v) {
              newAnswer.push(v);
            } else {
              newAnswer.push({ id: vObj.value, value: 0 });
            }
          });
        }
        state.formData.Question27.answer = newAnswer;
        const sum = newAnswer.reduce((acc, val) => (acc = acc + val.value), 0);
        if (sum !== 100) {
          state.complete = false;
        }
      }
    }
  },
};

/*
  GETTERS
 */
export const getters = {
  isValidZipCode(state) {
    if (state.formData?.Question3?.answer) {
      // validation here
      return true;
    }
    return false;
  },
  isQuestion12Ready(state) {
    if (state.formData?.Question11?.answer) {
      return true;
    }
    return false;
  },
};

function findInAllQuestions(questions, questionId) {
  let found = false;
  const q = questions.find(q => q._id === questionId);
  if (q) {
    return q;
  }
  const options = _flatten(questions.filter(q => q.options));
  const childQuestions = _flatten(
    options.map(o =>
      _flatten(o.options.filter(oo => oo.questions).map(q => q.questions))
    )
  );
  if (childQuestions.length) {
    return findInAllQuestions(childQuestions, questionId);
  }
}

/*
  ACTIONS
 */
export const actions = {
  answer({ commit, state }, input) {
    commit("setupQuestionStore", input);
    const question = findInAllQuestions(state.questions, input.id);
    if (question) {
      const obj = {
        id: input.id,
        answer: input[input.id].answer,
        num: question.num,
        reportedValue: input[input.id].reportedValue,
      };
      commit("setAnswer", obj);
    }
  },

  async saveAnswer({ commit, state }, { username, survey, questionValue }) {
    try {
      if (JSON.stringify(state.formData) !== JSON.stringify(state.lastSurvey)) {
        const data = {
          username: username,
          questionResponse: questionValue,
        };
        const result = await this.$axios.post(
          `/api/survey/${survey}/saveResponse`,
          data
        );
        console.log("saveAnswer result:", result.status);
        commit("setLastSurvey", state.formData);
      }
    } catch (error) {
      console.log("saveAnswer caught error");
      console.log(error);
      throw error;
    }
  },

  async saveSliderGroup(
    { commit, state },
    { username, survey, questionValue }
  ) {
    const data = {
      username: username,
      questionResponse: questionValue,
    };
    const result = await this.$axios.post(
      `/api/survey/${survey}/saveResponse`,
      data
    );
    commit("setLastSurvey", state.formData);
  },

  removeResponse({ commit, state, rootState }, { questionId }) {
    commit("clearAnswer", questionId);
    const username =
      rootState.user?.authenticatedUser?.username ||
      rootState.user?.authenticatedUser?.Username;
    if (username) {
      this.$axios.post("/api/survey/retrospective/removeResponse", {
        username,
        questionId,
      });
    }
  },

  zipCodeCheck({ commit, state }, zipCode) {
    const stateAbbreviation = state.formData.Question2.answer;
    this.$axios
      .post("/api/survey/zipCodeCheck", {
        state: stateAbbreviation,
        zip: zipCode,
      })
      .then(() => {
        commit("setQuestionMessage", { id: "Question3", message: null });
      })
      .catch(() => {
        commit("setQuestionMessage", {
          id: "Question3",
          message: "Invalid zip code for state",
        });
      });
  },

  removeLaterResponses({ commit, state, rootState }, questionId) {
    const qNum = state.formData[questionId]?.num;
    consola.info(`removeLaterResponses ${questionId}`);
    let keys = Object.keys(state.formData);
    keys = keys
      .filter(k => {
        if (
          state.formData[k].answer !== undefined &&
          state.formData[k].num >= qNum &&
          state.formData[k].num < 100
        ) {
          return k;
        }
      })
      .map(k => state.formData[k])
      .sort((a, b) => {
        // reverse sort by num field so later questions get removed first
        if (a.num > b.num) return -1;
        if (a.num < b.num) return 1;
        return 0;
      })
      .map(q => q.questionId);
    keys.forEach(k => commit("clearAnswer", k));

    const username =
      rootState.user?.authenticatedUser?.username ||
      rootState.user?.authenticatedUser?.Username;
    if (username) {
      this.$axios
        .post("/api/survey/retrospective/removeLaterResponses", {
          username,
          questionId,
        })
        .catch(e => {
          if (e.response.status === 404) {
            console.log(`${questionId} response not found`);
          } else {
            console.log(e);
          }
        });
    }
  },

  async reset({ commit }, { surveyId, username }) {
    if (!surveyId || !username) {
      console.log("Invalid parameters to reset action");
      return;
    }

    try {
      await this.$axios.post(`/api/survey/${surveyId}/reset`, {
        username,
      });
      commit("resetFormData");
    } catch (error) {
      console.log(error);
    }
  },

  updateQuestion13Value({ commit, state }) {
    const val1 = state.formData?.Question13a?.answer || 0;
    const val2 = state.formData?.Question13b?.answer || 0;
    const val3 = state.formData?.Question13c?.answer || 0;
    const val4 = state.formData?.Question13d?.answer || 0;
    const val5 = state.formData?.Question13e?.answer || 0;
    const val6 = state.formData?.Question13f?.answer || 0;
    const val7 = state.formData?.Question13g?.answer || 0;
    const val8 = state.formData?.Question13h?.answer || 0;
    const val9 = state.formData?.Question13i?.answer || 0;
    const val10 = state.formData?.Question13j?.answer || 0;
    const val11 = state.formData?.Question13k?.answer || 0;
    const total =
      val1 +
      val2 +
      val3 +
      val4 +
      val5 +
      val6 +
      val7 +
      val8 +
      val9 +
      val10 +
      val11;
    commit("setAnswer", { id: "Question13", answer: total, num: 22 });
    if (total !== 100) {
      commit("setQuestionMessage", {
        id: "Question13",
        message: `Values must total 100% to proceed, current values total ${total}%`,
      });
    } else {
      commit("setQuestionMessage", { id: "Question13", message: null });
    }
  },

  async clearUnansweredQuestions({ state }, { surveyId, username }) {
    const formDataKeys = Object.keys(state.formData);
    const unanswered = formDataKeys.filter(
      k => state.formData[k].answer === undefined
    );
    console.log({ unanswered });
    try {
      await this.$axios.post(
        `/api/survey/${surveyId}/removeUnansweredQuestions`,
        {
          username,
          unanswered,
        }
      );
    } catch (error) {
      console.log(error);
    }
  },

  async incrementQuestion({ commit, rootState }, { id, surveyId }) {
    commit("incrementQuestion", { id });
    try {
      const username = rootState.user?.authenticatedUser?.username;
      await this.$axios.post(`/api/survey/${surveyId}/incrementQuestionCount`, {
        username,
        id,
      });
    } catch (error) {
      console.log(error);
    }
  },

  setTacticsOptions({ commit, state, rootState, dispatch }, { options }) {
    // console.log({ options });
    if (options) {
      const username =
        rootState.user?.authenticatedUser?.username ||
        rootState.user?.authenticatedUser?.Username;

      // Clean up Question24-XXX responses not in options
      const formDataKeys = Object.keys(state.formData).filter(
        k => k.match(/Question24-/) && !k.match(/NONE/)
      );
      const valueKeys = options.map(v => `Question24-${v.value}`);
      const removedKeys = _difference(formDataKeys, valueKeys);
      removedKeys.forEach(k => {
        // Vue.delete(state.formData, k);
        commit("removeQuestion", k);
        // Remove from server here
        this.$axios.post("/api/survey/retrospective/removeResponse", {
          username,
          questionId: k,
        });
      });
      // commit("setTacticsOptions", options);
      commit("setQuestion24Options", options);

      const question24 = state.questions.find(q => q._id === "Question24");
      const answer = state.formData.Question24?.answer;
      if (answer) {
        const questionValue = QuestionValueFactory(question24, answer);
        dispatch("saveAnswer", {
          username,
          survey: "retrospective",
          questionValue,
        });
      }
      commit("setQuestion27Options", options);

      // const question24 = state.questions.find(q => q._id === "Question24");
      // const answer = state.formData.Question24?.answer;
      // if (answer) {
      //   const username =
      //     rootState.user?.authenticatedUser?.username ||
      //     rootState.user?.authenticatedUser?.Username;
      //   // console.log({ username });
      //   // console.log({ user: rootState.user });
      //   if (username) {
      //     const questionValue = QuestionValueFactory(question24, answer);
      //     dispatch("saveAnswer", {
      //       username,
      //       survey: "retrospective",
      //       questionValue,
      //     });
      //   }
      // }
    }
  },
};
