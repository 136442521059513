// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:82a658f6-e22e-4451-abde-4b8aba784fc6",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_FZWDV4NlM",
  aws_user_pools_web_client_id: "1khk7mn95ibm33mj1cjrlbud1c",
  oauth: {
    domain: "brcapp-dev.auth.us-east-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn:
      "http://localhost:3000/login,https://resiliencecalculator.com/login",
    redirectSignOut: "http://localhost:3000/,https://resiliencecalculator.com/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_appsync_graphqlEndpoint:
    "https://bdpsqvncrzc5nmlsmo3hjd73re.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
};

export default awsmobile;
