/*
  2WAV INC. PROVIDES THIS SOFTWARE TO YOU AS IS AND WITHOUT WARRANTY OF ANY KIND, EXPRESS, IMPLIED OR OTHERWISE, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.

  © 2021 2wav inc. All rights reserved, except as specifically provided by Agreement.
*/
/**
 * Create a formData entry from a question object.
 *
 * @param   {Object}  question       question object from the questions array
 * @param   {*}  answer         the submitted question answer
 * @param   {*}  reportedValue  the alternate reported value for an answere
 *
 * @return  {QuestionResponse}   the question response to store in formData
 */
const QuestionValueFactory = (question, answer, reportedValue) => {
  const questionId = question._id;
  const qv = {
    id: questionId,
    [questionId]: {
      questionId: questionId,
      num: question.num,
      answer: undefined,
    },
  };
  if (answer !== null && answer !== undefined) {
    qv[questionId].answer = answer;
    qv[questionId].createdAt = new Date();
    qv[questionId].timezoneOffset = -new Date().getTimezoneOffset();
  }
  if (reportedValue !== null && reportedValue !== undefined) {
    qv[questionId].reportedValue = reportedValue;
  }
  return qv;
};

export default QuestionValueFactory;
