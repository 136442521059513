<template>
  <section class="content">
    <div class="error__wrapper">
      <div class="content__text">
        <h1 v-if="error.statusCode === 404">Page not found</h1>
        <div v-else>
          <h1>An error occurred</h1>
          <div>
            <p>{{ error.message }}</p>
          </div>
        </div>

        <NuxtLink to="/">Home page</NuxtLink>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["error"],
  layout: "default", // you can set a custom layout for the error page
};
</script>

<style lang="css" scoped>
.error__wrapper {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-width: 1000px;
  padding-bottom: 40px;
}
.error {
  font-family: "Gotham A", "Gotham B", sans-serif;
  font-size: 18px;
  font-weight: 400;
}
</style>
