<template>
  <div class="domain-info">
    <AccordionBar
      v-if="this.$store.state.user.authenticatedUser"
      id="account"
      name="account"
      data-channel="account"
      title="Account"
      :open="accountOpen"
      @accordion-open="accordionToggle"
      @accordion-close-all="closeAll"
    >
      <client-only>
        <Account></Account>
      </client-only>
    </AccordionBar>
    <AccordionBar
      id="scenarios"
      name="scenarios"
      data-channel="scenario"
      title="About Disruptions"
      :open="scenariosOpen"
      @accordion-open="accordionToggle"
      @accordion-close-all="closeAll"
    >
      <div>
        <p>
          It is important that you think critically about those types of
          disruptions that are (or possibly will be) contributing to your
          business interruption. In other words, which of the below-listed
          problems are impacting (or will impact) your ability to produce
          pre-disaster levels of goods and services? Suggested starting values
          are provided, which may or may not accurately represent the situation
          your organization is facing or will face. Please adjust the
          percentages by selecting the plus and minus buttons for each source of
          disruption, ensuring that the total of all the sources accounts for
          100 percent of your organization’s business interruption.
        </p>
      </div>
    </AccordionBar>
    <AccordionBar
      id="tacticsX"
      name="tactics"
      data-channel="tactics"
      title="About Tactics"
      :open="tacticsOpen"
      @accordion-open="accordionToggle"
      @accordion-close-all="closeAll"
    >
      <div>
        <div class="domain-info--head">Conservation</div>
        <p>
          Maintaining intended production or service levels using lower amounts
          of an input or inputs (e.g., achieving the same level of production
          using less water, electricity or workers, without substituting other
          inputs for them).
        </p>

        <div class="domain-info--head">Resource Isolation</div>
        <p>
          Modifying a portion of your business operations to run without a
          critical input (e.g., following the disaster an office building could
          still be operational without water). This can include the isolation
          existing before the disaster or your extra effort to isolate it
          post-event.
        </p>

        <div class="domain-info--head">Input Substitution</div>
        <p>
          Replacing a production input in short supply with another (e.g.,
          replacing electricity by natural gas, water provided by pipeline with
          bottled or trucked water, whole milk with powdered milk, employees for
          tasks previously performed by machinery).
        </p>

        <div class="domain-info--head">Inventories</div>
        <p>
          Continuing business operations even when a critical input is in short
          supply by using emergency stockpiles and ordinary working supplies of
          production inputs (e.g., water tanks, canned goods, stockpiled
          materials in general).
        </p>

        <div class="domain-info--head">Excess Capacity</div>
        <p>
          Using a plant or equipment that was idle before the disaster in place
          of damaged counterparts (e.g., bring on-line physical assets not
          previously in use; such assets might include computers, equipment,
          vehicles, and vacant buildings).
        </p>

        <div class="domain-info--head">Relocation</div>
        <p>
          Moving some or all of the business activity to a new location (either
          temporary or permanent), including shifting data from onsite to
          “cloud” storage.
        </p>

        <div class="domain-info--head">Management Effectiveness</div>
        <p>
          Improving the efficiency of your business in the aftermath of the
          disaster (e.g., allowing for flexibility in business
          operations/procedures to minimize red tape during recovery, offering
          flexible working hours, minimizing reporting requirements or
          monitoring to facilitate more efficient or responsive operations).
        </p>

        <div class="domain-info--head">Import Substitution</div>
        <p>
          Importing some of your needed production inputs when you cannot obtain
          them from your usual local or regional suppliers, including new
          contractual arrangements (e.g., buying your materials or supplies from
          other regions or countries).
        </p>

        <div class="domain-info--head">Technological Change</div>
        <p>
          Improvising all or part of your production process without requiring a
          major investment expenditure (e.g., replacing two food preparation
          kitchens with one, replacing your paper accounting system with an
          automated one).
        </p>

        <div class="domain-info--head">Production Recapture</div>
        <p>
          Making up for lost production by working overtime or extra shifts.
          This must involve actual production and not include the selling of
          goods and services that were previously produced but could not be sold
          because of a slump in demand (e.g., adding an additional shift for
          employees or having them work additional overtime hours).
        </p>

        <div class="domain-info--head">Resource Sharing</div>
        <p>
          Hastening recovery through bargaining (e.g., renegotiating supply
          contracts with key suppliers), selective exchange of certain resources
          (e.g., short-term agreements for a defined period of time with other
          organizations,), creating new partnerships (e.g., building
          relationships with other businesses to share information and/or
          expertise), and resource sharing (e.g., joint ventures in order to bid
          for public contracts).
        </p>
      </div>
    </AccordionBar>
    <AccordionBar
      id="cost"
      name="cost"
      data-channel="cost"
      title="Cost or Save?"
      :open="costOpen"
      @accordion-open="accordionToggle"
      @accordion-close-all="closeAll"
    >
      <div>
        <p>
          For most firms, implementing a tactic incurs a cost. However, in some
          rare cases implementing a tactic can result in a savings (in addition
          to reduction of losses by using the tactic). Some examples include
          conserving a scarce resource or relocating to a new facility with a
          lower lease payment. Check “Save” if this tactic will be a savings to
          implement.
        </p>
      </div>
    </AccordionBar>
    <!-- <AccordionBar
      data-channel="damage"
      title="Property Damage"
      name="damage"
      :open="damageOpen"
      @accordion-open="accordionToggle"
      @accordion-close-all="closeAll"
    >
      <p>
        Our models might predict strange values for property values below $500,
        so the minimum allowed in this field is $500.
      </p>
    </AccordionBar>-->
    <AccordionBar
      id="resultsX"
      name="results"
      data-channel="results"
      title="About Results"
      :open="resultsOpen"
      @accordion-open="accordionToggle"
      @accordion-close-all="closeAll"
    >
      <div>
        <p>
          Our calculator uses data from real businesses in actual disasters to
          predict cost savings for your business. BCR means benefit-cost ratio.
        </p>
        <p>
          For each of the three tactics you chose, you will see three numbers:
          predicted benefit-cost ratio (BCR), sector median BCR, and best
          performers BCR (75th percentile). Similar visuals of results are
          presented for the Resilience Cost-Effectiveness Metric (RCE) and
          Resilience Metric (RM).
        </p>
        <p>
          The y-axis of the graph shows the percentage of firms achieving each
          level of the BCR, RM and RCE metric.
        </p>
        <p>
          Click anywhere along the x-axis to show the percentile for that value.
        </p>
      </div>
    </AccordionBar>
    <AccordionBar
      id="about"
      name="about"
      data-channel="about"
      title="About BRC"
      :open="aboutOpen"
      @accordion-open="accordionToggle"
      @accordion-close-all="closeAll"
    >
      <div class="domain-info--head">
        About the Business Resilience Calculator (BRC)
      </div>
      <p>
        The Business Resilience Calculator (BRC) is a decision support tool a
        business can use to evaluate its capability to respond to disasters. It
        also provides guidance on improving that capability. The BRC can help
        safeguard business continuity. It is designed with individual businesses
        in mind, as well as public and non-profit sector agencies tasked with
        providing support and assistance in building disaster resilience in the
        business community.
      </p>
      <p>
        The BRC focuses on various resilience tactics, or ways a firm can
        maintain a high level of business function during and after a disaster.
        Resilience is a popular buzzword, but the concept is linked directly to
        a business’s ability to produce goods and services. Unlike other
        resilience decision support tools, the BRC is not a simulation tool. It
        is built upon the actual experiences of firms like yours who have been
        through a disaster and experienced business interruption. Resilience
        helps to ensure that a firm can continue to produce its goods and/or
        services in the most cost-effective manner possible.
      </p>

      <div class="domain-info--head">
        What Does Resilience Mean for Your Business?
      </div>
      <p>
        The focus of broader definitions of resilience is on mitigation,
        primarily reducing property damage. However, this typically ignores the
        fact that economic losses continue after physical damage occurs. Your
        business can be stifled by a disruption to one of its utility lifelines,
        by employees being unable to travel to work, or by disruption(s) of
        critical inputs like infrastructure services.
      </p>
      <p>
        Each disruption your business faces in a disaster causes some degree of
        business interruption (BI). These BI losses lead to declines in sales
        revenue and profits. It is important to note that BI losses begin when
        the disaster strikes, and continue until your business has recovered.
      </p>
      <p>
        For some recent major catastrophes, BI losses have rivaled or exceeded
        property damage. For example, BI losses were four times the value of the
        World Trade Center in just the first year after 9/11. In the case of
        Hurricane Katrina, BI losses eventually exceeded property damage due to
        the protracted recovery.
      </p>
      <p>
        It is important to keep in mind that resilience is a process. Your
        business can adeptly improve its resilience
        <em>capacity</em> before the disaster strikes so that when the disaster
        does strike, you can implement the most cost-effective actions or
        tactics possible. The BRC is designed to support businesses by providing
        detailed statistical information about the resilience of other
        businesses like yours that have been through disasters. By learning
        about what has been effective and cost-effective for other firms like
        yours, your business can develop a well-informed plan for improving
        resilience capacity.
      </p>
      <p>
        The actions, or tactics, included in the BRC are based on
        well-established classes of actions used by both the private and public
        sectors. These tactics are the same well-established categories of
        tactics that businesses have used successfully and that research at
        leading universities and research centers have verified, in both
        individual business and supply-chain resilience domains.
      </p>
      <p>
        There are many things your business can do to maintain function while
        undertaking repair and reconstruction or waiting for the supply of
        workers, goods, and services to be restored. These resilience tactics
        include isolating various aspects of your business from disruption by
        reducing their dependence on various networks, stockpiling critical
        materials or finding substitutes for them, identifying back-up
        suppliers, relocating economic activity, and rescheduling production
        among others.
      </p>

      <div class="domain-info--head">How Does the BRC Work?</div>
      <p>
        The BRC begins with a straightforward resilience metric: losses
        prevented as a proportion of losses likely to occur if a given
        resilience tactic had not been implemented. Or, put more technically,
        avoided losses divided by maximum potential losses. For example, a major
        study found that 72% of BI losses following the 2001 World Trade Center
        attacks were prevented by the rapid relocation of businesses and
        government agencies to elsewhere in the New York Metro Area. Because the
        resilience metric is a proportion, it is easily interpreted as a
        percentage. This allows it to be readily comparable across tactics and
        firms. This metric is called the resilience metric, or RM for short.
      </p>
      <p>
        The RM represents the benefits (in terms of avoided losses) of
        implementing a given tactic. When combined with the cost of
        implementation, the BRC provides benefit-cost ratios (or BCRs). Many
        businesses are familiar with benefit-cost analysis—the BCR is the key
        measure used in these analyses. For example, one resilience tactic may
        have the potential to reduce your BI losses by 50%, but, even if it is
        10 times more expensive than a second tactic that can only reduce your
        BI losses by 20%, the second is still four times more cost-effective
        than the first.
      </p>
      <p>
        The BRC’s main resilience cost and effectiveness metrics are based upon
        these two metrics—the resilience metric (RM) and the benefit-cost ratio
        (BCR).
      </p>
      <p>
        The strength of the BRC is built upon its fundamentally-distinct
        approach to resilience analysis. It provides its resilience metrics
        based on the experiences of actual businesses like yours. Other software
        packages on the market today are based on simulations of firms in
        computer-generated environments. The BRC takes a different approach. Its
        estimates are based upon actual reported resilience effectiveness, and
        cost of implementation, from firms like yours that have been through
        disasters.
      </p>
      <p>
        It uses advanced statistical and econometric tools to analyze and
        evaluate that data, so that it can provide a deeper picture of the
        range, or distribution, of cost and effectiveness outcomes. In other
        words, it allows you to learn about your business’ resilience experience
        by looking beyond the simple average. By looking at the full
        distribution of each resilience metric, your business can learn not only
        from what others have done that has worked, but also from what others
        have done that has not been cost-effective.
      </p>
      <p>The BRC is a decision support suite that contains two main modules.</p>
      <ol>
        <li>
          The
          <em>Prospective Module</em> is a module designed for businesses that
          have not yet been through a disaster. It is designed to support
          decision-making and resilience capacity building for firms. The module
          allows firms to gain a deeper view of the full distribution of likely
          outcomes across a range of resilience tactics. If your business has
          not been through a disaster and is seeking to learn about what has
          worked, or has not worked, for other businesses like yours, the
          Prospective Module is for you.
        </li>

        <li>
          The
          <em>Retrospective Module</em> is a module designed for businesses that
          have been through a disaster. It helps users develop a full picture of
          their own resilience, as well as their own resilience metrics. It also
          provides a full distributional projection for these metrics, and
          allows users to view their resilience cost and effectiveness
          percentiles. If your business has just been through a disaster and you
          want to know where your business has over- or under-performed other
          firms like yours, the Retrospective Module is for you.
        </li>
      </ol>
      <p>
        Users begin by conducting a self-assessment of the types of disruptions
        (e.g., power outages, communications disruptions, supply chain problems)
        they have experienced, or for which they are seeking to build resilience
        capacity. Users are provided default values, but are encouraged to
        adjust those values based on their own unique business situation and
        operational environment. Then, based upon this self-assessment and other
        user-provided firmographic details (e.g., type of firm, number of
        employees), the BRC can identify the full range of resilience
        effectiveness and cost metrics appropriate to your business.
      </p>
      <p>
        It is important to note that in some cases, businesses can implement
        resilience tactics at negative cost. Because of this reality, the BRC
        allows users to evaluate both cost-incurring and cost-saving resilience
        tactics. This is because, in some less-common situations, firms’ cost of
        implementing a given resilience tactic can be a net savings. Examples
        would be conservation tactics, allowing workers to tele-commute, or
        shifting production hours to evenings when electricity is less expensive
        under time-of-use pricing. It is therefore important that the user
        carefully consider implementation costs when conducting its own
        self-assessment.
      </p>
      <p>
        In addition to providing a full statistical distribution to users for
        each metric, the BRC also provides useful summary measures. It provides
        a comparison for each metric to the sector in which the firm operates,
        as well as a comparison to firms who performed particularly well, called
        “best performers” or “best practice” firms. These are firms that
        achieved resilience metric outcomes for a given tactic at higher levels
        than 75 percent of firms like theirs. By comparing its own resilience
        score with best practice firms, a user can begin to decide on
        improvements to build further resilience capacity in the most
        cost-effective manner.
      </p>
      <p>
        The BRC also provides you with links to helpful information that can
        help you build resilience capacity and improve your resilience scores.
        These include examples of previous successes, information on assistance
        from government and non-government organizations, insurance options,
        etc.
      </p>

      <div class="domain-info--head">Research & Development Background</div>
      <p>
        The BRC is based on 20 years of research defining and measuring business
        and economic resilience to disasters based on actual and simulated
        events, such as the September 11 terrorist attacks, the Northridge
        Earthquake, Superstorm Sandy, Hurricane Harvey, and simulated
        catastrophic threats such as floods, severe storms, and cyber-attacks.
        Research underlying the BRC has been thoroughly vetted through
        peer-reviewed academic literature and by various government agencies.
        This research has been supported by the US Department of Homeland
        Security directly and through its Critical Infrastructure Resilience
        Institute (CIRI) and its Center for Risk and Economic Analysis of
        Terrorism Events (CREATE), and by the National Science Foundation,
        National Center for the Middle Market, US Coast Guard, US Geological
        Survey, California Department of Transportation, and The Ohio State
        University Risk Institute.
      </p>
      <p>
        The BRC is a valuable decision-support tool. It introduces you to a wide
        variety of resilience tactics, helps you measure your resilience
        capability and compare it to other businesses like yours. It also
        enables you to set targets for future improvements in resilience and
        provides you with help in doing so. The BRC can save you money and help
        you survive the next disaster.
      </p>

      <div class="domain-info--subhead">Researchers</div>
      <ul>
        <li>
          Noah Dormady, Ph.D.
          <br />John Glenn College of Public Affairs <br />The Ohio State
          University
        </li>
        <li>
          Adam Rose, Ph.D.
          <br />Price School of Public Policy and <br />Center for Risk and
          Economic Analysis of Terrorism Events (CREATE) <br />University of
          Southern California
        </li>
        <li>
          Alfredo Roa-Henriquez, Ph.D.
          <br />John Hopkins University <br />National Institute for Standards
          and Technology (NIST)
        </li>
        <li>
          Charles Blain Morin
          <br />John Glenn College of Public Affairs <br />The Ohio State
          University
        </li>
        <li>
          Heather Rosoff, Ph.D.
          <br />Center for Risk and Economic Analysis of Terrorism Events
          (CREATE) <br />University of Southern California
        </li>
      </ul>

      <div class="domain-info--subhead">Developers</div>
      <ul>
        <li>
          Anderson Wiese
          <br />2wav, Inc.
        </li>
        <li>
          Joseph DiGiovanna
          <br />2wav, Inc.
        </li>
        <li>
          Matthew Hinton
          <br />2wav, Inc.
        </li>
        <li>
          UI Designer
          <br />Gretchen Wieshuber <br />Studio 2D
        </li>
      </ul>
    </AccordionBar>

    <div class="domain-info__entry">
      <div class="domain-info__header">
        <span class="domain-info__title">
          <nuxt-link to="/covid-19" class="menu-reset" @click.native="navigate"
            >COVID-19 Resilience</nuxt-link
          >
        </span>
      </div>
    </div>

    <div class="domain-info__entry">
      <div class="domain-info__header">
        <span class="domain-info__title">
          <nuxt-link
            :to="retrospectivePath"
            class="menu-reset"
            @click.native="navigate($event, 'retrospective')"
            >{{ retrospectiveText }}</nuxt-link
          >
        </span>
      </div>
    </div>

    <div class="domain-info__entry">
      <div class="domain-info__header">
        <span class="domain-info__title">
          <nuxt-link
            to="/scenario"
            class="menu-reset"
            @click.native="navigate($event, 'info')"
            >Start Over</nuxt-link
          >
        </span>
      </div>
    </div>

    <div
      v-if="this.$store.state.user.authenticatedUser"
      class="domain-info__entry"
    >
      <div class="domain-info__header">
        <span class="domain-info__title">
          <a href="#" class="menu-reset" @click.prevent="logout">Logout</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import consola from "consola";
import EventBus from "../../EventBus";
import Account from "../../page.blocks/Account/Account";
import AccordionBar from "./AccordionBar";

export default {
  components: {
    AccordionBar,
    Account,
  },
  data() {
    return {
      accountOpen: false,
      scenariosOpen: false,
      tacticsOpen: false,
      costOpen: false,
      damageOpen: false,
      resultsOpen: false,
      aboutOpen: false,
    };
  },
  mounted() {
    EventBus.$on("accordion-close-all", () => {
      // consola.info("EventBus accordion-close-all handler");
      this.closeAll();
    });
  },
  computed: {
    retrospectivePath() {
      let path = "/survey/retrospective";
      if (this.hasSurvey) {
        path = "/results";
      }
      return path;
    },
    hasSurvey() {
      return this.$store.state.survey.hasSurvey;
    },
    retrospectiveText() {
      let text = "Retrospective Survey";
      if (this.hasSurvey) {
        text = "Customized Results";
      }
      return text;
    },
  },
  methods: {
    accordionToggle(name) {
      // consola.info(`toggle accordion: ${name}`);
      [
        "account",
        "scenarios",
        "tactics",
        "cost",
        "damage",
        "results",
        "about",
      ].forEach(acc => {
        if (name !== acc) {
          this[`${acc}Open`] = false;
        }
      });
      this[`${name}Open`] = true;
    },
    closeAll() {
      this.accountOpen = false;
      this.scenariosOpen = false;
      this.tacticsOpen = false;
      this.costOpen = false;
      this.damageOpen = false;
      this.resultsOpen = false;
      this.aboutOpen = false;
    },
    navigate(evt, name) {
      this.$store.commit("hideDomainInfo");
      if (name === "retrospective" && this.hasSurvey) {
        this.$cookies.set("showCustomized", true);
        if (this.$route.name === "results") {
          const newRoute = { ...this.$route };
          // this.$router.replace(newRoute).catch(err => {});
          window.location = "/results";
        }
      } else {
        this.$cookies.remove("showCustomized");
      }
    },
    logout() {
      this.$store.commit("hideDomainInfo");
      this.$store.dispatch("user/clear");
      this.$store.dispatch("clear");
      this.$cookies.remove("cognito-username");
      this.$cookies.remove("brc-token");

      window.Auth.signOut()
        .then(data => {
          consola.log(data);
          localStorage.clear();
          this.$router.push("/");
        })
        .catch(err => {
          consola.debug(err);
        });
    },
  },
};
</script>

<style lang="scss">
#results-description-list {
  list-style: none;
}
.sublist {
  padding-left: 2rem !important;
}
.menu-reset {
  text-decoration: none;
  color: var(--dark-blue);
}
.domain-info {
  // position: relative;
  // z-index: 10;
  // top: 80;
  // left: calc((100vw - 768px) / 2);
  // max-width: 768px;
  @media (max-width: 780px) {
    left: 0;
    width: 100%;
    max-width: unset;
  }
  font-family: "Gotham A", "Gotham B", sans-serif;
  p {
    font-size: 14pt;
    line-height: 20pt;
    color: var(--cool-gray-11);
  }
  ul {
    li {
      list-style: none;
      margin-top: 1rem;
    }
  }
  &--head {
    color: var(--cool-gray-11);
    font-size: 14pt;
    line-height: 20pt;
    font-weight: bold;
    margin: 1rem auto 0.5rem auto;
    max-width: 714px;
  }
  &--subhead {
    color: var(--cool-gray-11);
    font-weight: bold;
    font-size: 12pt;
    margin: 1rem auto 0.5rem auto;
    max-width: 714px;
  }
  margin: 0;
  padding: 0;
  &__entry {
    color: var(--dark-blue);
    background-color: var(--light-blue-brc);
    border-bottom: 1px solid var(--dark-blue);
    margin: 0;
    padding: 0;
    &:hover {
      background-color: var(--bright-blue);
      color: white;
    }
  }
  &__header {
    font-weight: bold;
    line-height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    max-width: 768px;
    margin: 0 auto;
    padding-right: 35px;
  }
  &__icon {
    margin-right: 0.5rem;
    cursor: pointer;
  }
  &__title {
    cursor: pointer;
  }
  &__content {
    display: none;
    background-color: #ffffff;
    color: var(--cool-gray-11);
    border-top: 1px solid var(--dark-blue);
    padding-bottom: 20px;
    ul,
    li,
    p {
      font-size: 12pt;
      line-height: 16pt;
      max-width: 714px;
      margin: 0 auto;
      padding: 0 0 0.5rem 0;
    }
  }
  &__close {
    font-style: italic;
    font-weight: bold;
    color: var(--bright-blue);
    &:hover {
      color: var(--dark-blue);
    }
  }
}
.domain-info__content div:first-of-type {
  padding-top: 20px;
}
.domain-info--head:first-of-type {
  margin-top: 0;
}
.domain-info__content div.row {
  padding-top: 0;
}
</style>
