import { Auth } from "aws-amplify";
import consola from "consola";
// import disruptions from "../components/data/disruptions";

// Only allow state changes via mutation, don't run strict mode in production.
export const strict = process.env.NODE_ENV !== "production";

const COGNITO_URL = "https://cognito-idp.us-east-1.amazonaws.com/";

export const state = () => ({
  createAccount: false,
  acceptTerms: false,
  zipCode: "",
  businessCategory: "",
  businessType: "",
  employeeCount: "",
  ownBuilding: "",
  yearFounded: "",
  annualSalesRevenue: "",
  scenario: "",
  disruptionValues: [],
  damage: 500,
  selectedTactics: [],
  costSaveTactics: [],
  showDomainInfo: false,
  authState: null,
  confirmAccountStep: false,
  results: [],
  loaded: false,
  showInfoRequired: false,
});

export const mutations = {
  setCreateAccount(state, value) {
    state.createAccount = value;
  },
  acceptTerms(state) {
    state.acceptTerms = true;
  },
  zipCode(state, value) {
    state.zipCode = value;
  },
  businessCategory(state, value) {
    state.businessCategory = value;
  },
  businessType(state, value) {
    state.businessType = value;
  },
  employeeCount(state, value) {
    state.employeeCount = value;
  },
  ownBuilding(state, value) {
    state.ownBuilding = value;
  },
  yearFounded(state, value) {
    state.yearFounded = value;
  },
  annualSalesRevenue(state, value) {
    state.annualSalesRevenue = value;
  },
  scenario(state, value) {
    state.scenario = value;
    state.results = [];
  },
  setDisruptionValues(state, value) {
    if (value) {
      state.disruptionValues = value;
    } else {
      state.disruptionValues = [];
    }
    state.results = [];
  },
  setDamage(state, value) {
    state.damage = value;
    state.results = [];
  },
  selectedTactics(state, value) {
    if (value) {
      state.selectedTactics = value;
    } else {
      state.selectedTactics = [];
    }
    state.results = [];
  },
  setCostSaveTactics(state, value) {
    if (value) {
      state.costSaveTactics = value;
    } else {
      state.costSaveTactics = [];
    }
  },
  results(state, value) {
    state.results = [];
  },
  toggleShowDomainInfo(state) {
    state.showDomainInfo = !state.showDomainInfo;
  },
  hideDomainInfo(state) {
    state.showDomainInfo = false;
  },
  showDomainInfo(state) {
    state.showDomainInfo = true;
  },
  setAuthState(state, value) {
    state.authState = value;
  },
  setResults(state, value) {
    state.results = value;
  },
  addResult(state, value) {
    state.results.push(value);
  },
  setConfirmAccountStep(state, value) {
    state.confirmAccountStep = value;
  },
  setLoaded(state, value = true) {
    state.loaded = value;
  },
  setShowInfoRequired(state, value) {
    state.showInfoRequired = value;
  },
};

export const getters = {
  scenarioData(state) {
    return {
      acceptTerms: state.acceptTerms,
      zipCode: state.zipCode,
      businessCategory: state.businessCategory,
      employeeCount: state.employeeCount,
      ownBuilding: state.ownBuilding,
      yearFounded: state.yearFounded,
      annualSalesRevenue: state.annualSalesRevenue,
      scenario: state.scenario,
      disruptionValues: state.disruptionValues,
      damage: state.damage,
      selectedTactics: state.selectedTactics,
      costSaveTactics: state.costSaveTactics,
      results: state.results,
    };
  },
  profileData(state) {
    return {
      acceptTerms: state.acceptTerms,
      zipCode: state.zipCode,
      businessCategory: state.businessCategory,
      employeeCount: state.employeeCount,
      ownBuilding: state.ownBuilding,
      yearFounded: state.yearFounded,
      annualSalesRevenue: state.annualSalesRevenue,
    };
  },
  username(state) {
    // return state.user?.authenticatedUser?.username;
    return (
      state.user?.authenticatedUser?.Username ||
      state.user?.authenticatedUser?.username
    );
  },
  // email(state) {
  //   return state.user?.authenticatedUser?.attributes?.email;
  // },
};

export const actions = {
  async nuxtServerInit({ commit }, { app, redirect }) {
    // consola.info("nuxtServerInit");
    // const token = app.$cookies.get("brc-token");
    // if (token) {
    //   try {
    //     const { data: user } = await app.$axios.post(
    //       COGNITO_URL,
    //       { AccessToken: token },
    //       {
    //         headers: {
    //           "Content-Type": "application/x-amz-json-1.1",
    //           "X-Amz-Target": "AWSCognitoIdentityProviderService.GetUser",
    //         },
    //       }
    //     );
    //     if (user) {
    //       console.log("valid user token");
    //     }
    //   } catch (error) {
    //     console.error(`nuxtServerInit error: ${error.message}`);
    //   }
    // }
  },

  clear({ commit, state }) {
    commit("acceptTerms", "");
    commit("zipCode", "");
    commit("businessCategory", "");
    commit("businessType", "");
    commit("employeeCount", "");
    commit("ownBuilding", "");
    commit("yearFounded", "");
    commit("annualSalesRevenue", "");
    commit("scenario", "");
    // commit(
    //   "setDisruptionValues",
    //   disruptions.map(e => e.initialPercentage)
    // );
    commit("setDisruptionValues", []);
    commit("setDamage", null);
    commit("setResults", []);
    commit("selectedTactics", []);
    commit("setCostSaveTactics", []);
    commit("hideDomainInfo");
  },
  setScenarioValues({ commit }, scenario) {
    if (scenario === undefined || scenario === "undefined") {
      consola.debug("setScenarioValues got bad scenario value");
    }
    commit("acceptTerms", scenario.profile?.acceptTerms);
    commit("zipCode", scenario.profile?.zipCode);
    commit("businessCategory", scenario.profile?.businessCategory);
    commit("employeeCount", scenario.profile?.employeeCount);
    commit("ownBuilding", scenario.profile?.ownBuilding);
    commit("yearFounded", scenario.profile?.yearFounded);
    commit("annualSalesRevenue", scenario.profile?.annualSalesRevenue);
    commit("scenario", scenario.scenario);
    commit("setDisruptionValues", scenario.disruptions);
    commit("setDamage", scenario.damage);
    commit("selectedTactics", scenario.tactics);
    commit("setCostSaveTactics", scenario.costSaveTactics);
    commit("setResults", scenario.results);
  },
};
