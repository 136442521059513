<template>
  <div v-if="!showCookies">
    <div v-if="this.$route.path === '/'" class="cookie-banner">
      <h2>This website uses cookies.</h2>
      <button class="accordion-button" @click="toggleText">More info.</button>
      <button class="button button--rounded" @click="hideCookieNotice">
        OK
      </button>
      <div class="accordion-text" v-if="showPanel">
        <h3>Browser Cookies and Local Storage</h3>
        <p>
          The Business Resilience Calculator uses necessary cookies to make our
          site work. Cookies enable core functionality such as security, network
          management, and accessibility.
        </p>
        <p>
          <span>Google Analytics:</span> These cookies are used to collect
          information about how visitors use our web tool. We use the
          information to compile reports and to help us imporove the experience.
          The cookies collect information in a way that does not directly
          identify anyone.
        </p>
        <p>
          <span>Vimeo:</span> We embed videos from our official Vimeo channel.
          When you press play, Vimeo will drop third party cookies to enable the
          video to play and to collect analytics data such as how long a viewer
          has watched the video. These cookies do not track individuals.
        </p>
        <p>
          <span>Local Storage:</span> The Business Resilience Calculator uses
          Cognito Identity Service Provider for user accounts. Some data is
          stored on your computer so that you do not have to reenter it, and it
          remembers where you were the last time you visited.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import consola from "consola";

export default {
  data() {
    return {
      showPanel: false,
    };
  },

  computed: {
    showCookies() {
      const cookieState = this.$store.state.user["cookie:accepted"];
      const cookie = this.$cookies.get("cookie:accepted");
      if (process.server) {
        if (cookie === true) {
          return true;
        }
      }
      if (process.client) {
        const cookieLocal = window.localStorage.getItem("cookie:accepted");
        if (cookieLocal === "true") {
          return true;
        }
        if (cookieState === true) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    toggleText() {
      if (this.showPanel == false) {
        return (this.showPanel = true);
      } else {
        return (this.showPanel = false);
      }
    },
    hideCookieNotice() {
      window.localStorage.setItem("cookie:accepted", true);
      this.$cookies.set("cookie:accepted", true);
      this.$store.commit("user/setCookie", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie-banner {
  background-color: var(--bright-blue);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  text-align: center;
  line-height: 20pt;
  padding: 7px 0 4px 0;
  h2 {
    color: white;
    font-family: "Gotham A", "Gotham B", sans-serif;
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    line-height: 48px;
    display: inline-block;
  }
  button {
    cursor: pointer;
    display: inline-block;
  }
  button.accordion-button {
    color: var(--dark-blue);
    background-color: rgba(255, 255, 255, 0);
    border: none;
    font-family: "Gotham A", "Gotham B", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 20pt;
  }
  button.accordion-button:hover {
    color: white;
    border: none;
  }
  button.accordion-button:focus {
    color: white;
    border: none;
    outline: none;
  }
  button.button:hover {
    color: white;
    background-color: var(--dark-blue);
  }
  .accordion-text {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 714px;
    margin-left: auto;
    margin-right: auto;
    h3 {
      font-family: "Gotham A", "Gotham B", sans-serif;
      font-size: 20px;
      // color: var(--cool-gray-11);
    }
    p {
      font-family: "Gotham A", "Gotham B", sans-serif;
      // color: var(--cool-gray-11);
      span {
        font-weight: 500;
      }
    }
  }
}
</style>
