<template>
  <div class="domain-info__entry" :class="{ open: isOpen }">
    <div class="domain-info__header">
      <span class="domain-info__icon">
        <font-awesome-icon
          icon="caret-up"
          size="1x"
          :class="{ hidden: !isOpen }"
          @click="toggleContent"
        />
        <font-awesome-icon
          icon="caret-down"
          size="1x"
          :class="{ hidden: isOpen }"
          @click="toggleContent"
        />
      </span>
      <span class="domain-info__title" @click="toggleContent">{{ title }}</span>
    </div>
    <div class="domain-info__content">
      <slot />
      <p>
        <a class="domain-info__close" @click.prevent.stop="hideDomainInfo"
          >Close</a
        >
      </p>
    </div>
  </div>
</template>

<script>
import consola from "consola";
import EventBus from "../../EventBus";

export default {
  props: {
    title: {
      type: String,
      default: "UNSET TITLE VALUE",
    },
    name: {
      type: String,
      default: "UNSET NAME VALUE",
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    open(newValue, oldValue) {
      if (newValue === false) {
        // consola.info(`${this.name} open val: ${newValue}`);
        this.closeEntry(this.$el);
      }
    },
  },
  created() {
    EventBus.$on("child-closed", () => {
      // consola.info("received child-closed event");
      this.hideDomainInfo();
    });
  },
  mounted() {
    const channel = this.$el.dataset.channel;
    if (channel) {
      consola.info(`listening on ${channel}`);
      EventBus.$on(channel, () => {
        this.openEntry(this.$el);
        // consola.info("emit accordion-open");
        this.$emit("accordion-open", channel);
      });
    }
  },
  methods: {
    toggleContent(e) {
      this.isOpen = !this.isOpen;
      this.toggleEntry(e);
      if (this.isOpen) {
        this.$emit("accordion-open", this.name);
      }
    },
    hideDomainInfo() {
      this.closeEntry(this.$el);
      this.$store.commit("toggleShowDomainInfo");
      this.$emit("accordion-close-all");
    },
    closeEntry(el) {
      // const span = el.querySelector(".domain-info__icon");
      // const icons = Array.from(span.querySelectorAll("svg"));
      const content = el.querySelector(".domain-info__content");
      content.style.display = "none";
      // icons.forEach(icon => {
      //   if (Array.from(icon.classList).includes("hidden")) {
      //     icon.classList.remove("hidden");
      //   } else {
      //     icon.classList.add("hidden");
      //   }
      // });
      this.isOpen = false;
    },
    openEntry(el) {
      // const span = el.querySelector(".domain-info__icon");
      // const icons = Array.from(span.querySelectorAll("svg"));
      const content = el.querySelector(".domain-info__content");
      content.style.display = "block";
      // icons.forEach(icon => {
      //   if (Array.from(icon.classList).includes("hidden")) {
      //     icon.classList.remove("hidden");
      //   } else {
      //     icon.classList.add("hidden");
      //   }
      // });
      this.isOpen = true;
    },
    toggleEntry(e) {
      const entry = e.currentTarget.closest(".domain-info__entry");
      const content = entry.querySelector(".domain-info__content");
      if (content.style.display === "none" || content.style.display === "") {
        this.openEntry(entry);
      } else {
        this.closeEntry(entry);
      }
    },
  },
};
</script>

<style lang="scss">
.open {
  background-color: var(--bright-blue) !important;
  color: white !important;
}
</style>
