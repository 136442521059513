<template>
  <div class="account" @click.stop>
    <div v-show="this.$route.name != 'info'" class="account-controls">
      <nuxt-link to="/info">
        <button
          class="button account-controls__review"
          @click="emitChildClosed"
        >
          Review Profile
        </button>
      </nuxt-link>
    </div>
    <div class="account-form">
      <div class="account-form__input">
        <label for="fname">
          Name:
          <input
            id="fname"
            v-model="fname"
            type="text"
            name="fname"
            class="account-form__input--text"
            placeholder="First Name"
          />
          <input
            id="mname"
            v-model="mname"
            type="text"
            name="mname"
            class="account-form__input--text small"
            placeholder="M.I."
          />
          <input
            id="lname"
            v-model="lname"
            type="text"
            name="lname"
            class="account-form__input--text"
            placeholder="Last Name"
          />
        </label>
      </div>
      <div class="account-form__input">
        <label for="bname">
          Business Name:
          <input
            id="bname"
            v-model="bname"
            type="text"
            name="bname"
            class="account-form__input--text"
          />
        </label>
      </div>
      <div class="account-form__static">
        <label for="bname">
          Email:
          <span class="account-form__static--value">{{ email }}</span>
        </label>
      </div>
      <div class="account-form__submit">
        <button class="button button--rounded" @click.prevent="submit">
          Submit
        </button>
        <font-awesome-icon
          ref="spinner"
          :icon="['fas', 'spinner']"
          style="color: black"
          size="2x"
          spin
        ></font-awesome-icon>
      </div>
      <div class="account-form__link">
        <a href="#" @click.prevent="resetPassword">Reset password</a>
      </div>
      <div class="account-form__link">
        <nuxt-link to="/user-agreement"
          >Terms of Use and Privacy Policy</nuxt-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import consola from "consola";
import EventBus from "../../EventBus";

export default {
  async fetch() {
    const username = this.$store.getters.username;
    consola.info(`username from store: ${username}`);
    try {
      const result = await this.$axios.get(
        `/api/user/personal?username=${username}`
      );
      if (result) {
        const data = result.data;
        if (data.error) {
          consola.debug(data.error);
          // this.$router.push("/welcome");
        } else {
          const { personalProfile } = data;
          // consola.info("personalProfile:", personalProfile);
          if (personalProfile) {
            this.fname = personalProfile.fname;
            this.mname = personalProfile.mname;
            this.lname = personalProfile.lname;
            this.bname = personalProfile.businessName;
          }
        }
      } else {
        this.$router.push("/");
      }
    } catch (error) {
      consola.debug(error.message);
    }
  },
  data() {
    return {
      fname: null,
      mname: null,
      lname: null,
      bname: null,
    };
  },
  computed: {
    email() {
      return this.$store.getters["user/email"];
    },
  },

  methods: {
    emitChildClosed() {
      consola.log("emit child-closed");
      EventBus.$emit("child-closed");
    },
    async submit() {
      const spinner = this.$refs.spinner;
      try {
        spinner.style.display = "flex";
        const username = this.$store.getters.username;
        await this.$axios.post("/api/user/personal", {
          username,
          personalProfile: {
            fname: this.fname,
            mname: this.mname,
            lname: this.lname,
            businessName: this.bname,
          },
        });
      } catch (e) {
        consola.debug(e.message);
      } finally {
        setTimeout(() => (spinner.style.display = "none"), 250);
      }
    },
    async resetPassword() {
      await window.Auth.signOut();
      this.$store.commit("hideDomainInfo");
      this.$store.commit("user/setForgotPassword", true);
      console.log("redirect 6");
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.small {
  max-width: 40px;
}
.account {
  width: 100%;
  max-width: 714px;
  margin: 0 auto;
  padding: 0;
  .account-controls {
    margin: 0 0 1rem 0;
    &__review {
      height: 30px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      margin: 0;
      background-color: var(--bright-blue);
      color: var(--dark-blue);
      &:hover {
        background-color: var(--bright-blue);
        color: white;
      }
    }
  }
  .account-form {
    &__input {
      margin-bottom: 0.5rem;
      label {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: stretch;
        align-items: flex-start;
        width: 100%;
        padding: 0;
        margin: 0;
        color: var(--cool-gray-5);
        font-weight: 500;
        font-size: 14pt;
        line-height: 20pt;
        input {
          padding: 2px 6px;
          font-size: 14pt;
          font-weight: 500;
          line-height: 20pt;
          background-color: var(--cool-gray-2);
          border: 0;
          color: var(--cool-gray-11);
          &::placeholder {
            color: white;
            font-style: italic;
            font-family: "Gotham A", "Gotham B", sans-serif;
            padding-left: 0.5rem;
          }
          &:first-child {
            margin-left: 1rem;
            margin-right: 1rem;
            flex: 1 1 auto;
          }
          &:last-child {
            margin-left: 1rem;
            flex: 3 1 auto;
            align-self: auto;
          }
          &:only-child {
            margin: 0 0 0 1rem;
          }
        }
      }
    }
    &__static {
      label {
        color: var(--cool-gray-5);
        font-weight: 500;
        font-size: 14pt;
        line-height: 20pt;
      }
      &--value {
        color: var(--cool-gray-11);
        margin-left: 0.5rem;
      }
    }
    &__submit {
      display: flex;
      margin: 1rem 0;
      button {
        height: 30px;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        margin: 0;
        background-color: var(--cool-gray-2) !important;
        &:hover {
          background-color: var(--bright-blue) !important;
          color: white !important;
        }
      }
      svg {
        display: none;
        margin-left: 0.5rem;
      }
    }
    &__link {
      a {
        margin: 1rem 0;
        font-weight: 500;
        font-style: italic;
        font-size: 14pt;
        line-height: 20pt;
        color: var(--bright-blue);
        text-decoration: none;
        &:hover {
          color: var(--dark-blue);
        }
      }
    }
  }
}
</style>
