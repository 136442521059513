<template>
  <div class="page">
    <div v-if="this.$route.path !== '/'">
      <Header :welcome="welcome"></Header>
    </div>
    <transition
      v-if="this.$route.path !== '/' && this.$route.path !== '/login'"
      name="slide"
      tag="div"
      class="transition-slide"
    >
      <DomainInfo v-if="showDomainInfo"></DomainInfo>
    </transition>
    <nuxt />
    <CookieBanner v-if="this.$route.path === '/'"></CookieBanner>
  </div>
</template>

<script>
import consola from "consola";
import { Auth, Hub } from "aws-amplify";
import { isEmpty } from "lodash";
import Header from "../components/common.blocks/Header";
import DomainInfo from "../components/common.blocks/DomainInfo/DomainInfo";
import CookieBanner from "../components/common.blocks/CookieBanner";
import "../components/common.blocks/global.css";
import "../components/common.blocks/button.css";
import "../components/common.blocks/content.css";
import "../components/common.blocks/transition.css";

async function authUser(event, { $router, $route, $store, $axios, $cookies }) {
  consola.info(`-- authUser function called with ${event}--`);
  // debugging guard statement
  // if (event !== "signedIn") return;

  if (event === "signIn") {
    // reset state
    $store.commit("setConfirmAccountStep", false);
  }

  if (event === "signOut") {
    consola.info("-- signOut event --");
    $store.commit("user/updateAuthenticatedUser", null);
    if ($route.path !== "/login") {
      $router.push("/");
    }
    return;
  }

  try {
    const user = await Auth.currentAuthenticatedUser();
    const username = user?.username || user?.Username;
    consola.info("Got username in default: " + username);
    if (!user || !username) {
      return;
    } else {
      $cookies.set("cognito-username", username, {
        path: "/",
        maxAge: 60 * 60 * 24 * 7,
      });
      $cookies.set("brc-token", user.signInUserSession?.accessToken?.jwtToken, {
        path: "/",
        maxAge: 60 * 60 * 24 * 7,
      });
    }

    switch (event) {
      case "signedIn": {
        // $store.commit("user/updateAuthenticatedUser", user);
        // const createResult = await $axios.post("/api/user/create", {
        //   username,
        // });
        // consola.info("createResult status:", createResult.status);

        // check for scenario
        const scenarioResult = await $axios.get(
          `/api/user/scenario?username=${username}`
        );
        consola.info("scenarioResult:", scenarioResult);
        $store.commit("user/updateAuthenticatedUser", user);
        if (
          !scenarioResult?.data.hasOwnProperty("profile") ||
          !scenarioResult?.data?.profile?.acceptTerms
        ) {
          consola.info("go to user-agreement");
          $router.push("/user-agreement");
        } else {
          consola.info(
            "Accepted terms: " + scenarioResult?.data?.profile?.acceptTerms
          );
          if (
            !scenarioResult?.data?.profile?.businessCategory ||
            !scenarioResult?.data?.profile?.employeeCount
          ) {
            // no business profile
            consola.info("go to info");
            $router.push("/info");
          } else if (!scenarioResult?.data?.scenario) {
            // got a business profile but no scenario
            $store.dispatch("setScenarioValues", scenarioResult.data);
            consola.info("go to scenario");
            $router.push("/scenario");
          } else if (!scenarioResult?.data?.disruptions.length) {
            // got a business profile & scenario, but no disruption values
            $store.dispatch("setScenarioValues", scenarioResult.data);
            consola.info("go to disruption");
            $router.push("/disruptions");
          } else if (
            !scenarioResult?.data?.damage ||
            !scenarioResult?.data.hasOwnProperty("damage")
          ) {
            // got a profile, scenario, and disruption values, but no damage
            $store.dispatch("setScenarioValues", scenarioResult.data);
            consola.info("go to damage");
            $router.push("/damage");
          } else if (!scenarioResult?.data?.tactics.length) {
            // got a profile, scenario, disruption values, and damage, but no tactics
            $store.dispatch("setScenarioValues", scenarioResult.data);
            consola.info("go to tactics");
            $router.push("/tactics");
          }
          // we supposedly have everything then go to results
          else {
            $store.dispatch("setScenarioValues", scenarioResult.data);
            consola.info("go to results");
            $router.push("/results");
          }
        }
        break;
      }
      case "signIn": {
        consola.info(`-- ${event} event --`);
        $store.commit("user/updateAuthenticatedUser", user);
        const createResult = await $axios.post("/api/user/create", {
          username: username,
          email: user.attributes.email,
        });
        consola.info("createResult status:", createResult.status);
        // check for scenario
        const scenarioResult = await $axios.get(
          `/api/user/scenario?username=${username}`
        );
        consola.info("scenarioResult:", scenarioResult);
        if (!scenarioResult?.data?.profile?.acceptTerms) {
          consola.info("go to user-agreement");
          $router.push("/user-agreement");
        }
        break;
      }
      case "signedOut": {
        consola.info("-- signedOut event --");
        $store.commit("user/updateAuthenticatedUser", null);
        $store.dispatch("clear");
        if ($router.path !== "/") {
          $router.push("/");
        }
        $cookies.remove("cognito-username");
        $cookies.remove("brc-token");
        break;
      }
      // case "signOut":
      default: {
        consola.info(`unhandled authState: ${event}`);
        break;
      }
    }
  } catch (error) {
    consola.info("in catch");
    if (
      event === "signUp" ||
      event === "signIn" ||
      event === "confirmSignUp" ||
      event === "forgotPassword" ||
      event === "forgotPasswordSubmit" ||
      event === "signIn_failure"
    ) {
      const btn = document.querySelector(
        '[data-test="sign-in-sign-in-button"]'
      );
      if (btn !== null) {
        btn.removeAttribute("disabled");
      }
      if (event === "confirmSignUp") {
        // Show signup help info
        $store.commit("setConfirmAccountStep", true);
        $store.commit("setCreateAccount", false);
      }
      if (event === "signIn") {
        $store.commit("user/setForgotPassword", false);
        console.log("redirect 4");
        $router.push("/login");
      }
      return;
    }
    consola.info("signedIn error");
    consola.error(error);
    console.log("redirect 5");
    $router.push("/login");
  }
}

export default {
  components: {
    Header,
    DomainInfo,
    CookieBanner,
  },

  data() {
    return {
      welcome: this.$router.currentRoute.path === "/",
    };
  },

  computed: {
    showDomainInfo() {
      return this.$store.state.showDomainInfo;
    },
  },

  watch: {
    $route(to) {
      this.welcome = to.path === "/";
    },
  },

  async beforeCreate() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        this.$store.commit("user/updateAuthenticatedUser", user);
        const result = await this.$axios.get(
          `/api/user/scenario?username=${user.username}`
        );
        // consola.info("-- Layout got scenario from db");
        // consola.info("result", result);
        this.$store.dispatch("setScenarioValues", result.data);
      } else {
        consola.info("currentAuthenticatedUser didn't find user");
        this.$store.commit("user/updateAuthenticatedUser", null);
      }
    } catch (error) {
      // consola.info("currentAuthenticatedUser user status:", error);
      this.$store.commit("user/updateAuthenticatedUser", null);
    }
  },
  mounted() {
    // NOTE: This dynamic import is a workaround for a regular import of AmplifyEventBus blowing up
    import("aws-amplify-vue").then(({ AmplifyEventBus }) => {
      AmplifyEventBus.$on("authState", info => {
        consola.info("amplify event", info);
        authUser(info, this);
      });
    });
    // NOTE: This is required for social sign in events
    Hub.listen(/.*/, data => {
      const { payload } = data;
      // consola.info("A new auth event has happened: ", data);
      consola.info("Hub event", data.payload);
      authUser(payload.event, this);
    });
  },
};
</script>

<style lang="scss">
#__nuxt,
#__layout {
  height: 100%;
}

a {
  cursor: pointer;
}

.page {
  position: relative;
}

.layout-default {
  height: 100%;
}

.content {
  z-index: 0;
}

.hidden {
  display: none;
}
/* Transition for dropdown */

.slide-enter,
.slide-leave-to {
  height: 0;
}
.slide-enter-active,
.slide-leave-active {
  height: calc(370px / 4);
}
.slide-enter-to {
  height: 370px;
  opacity: 1;
}

.slide-leave-to {
  height: 0;
}
.slide-leave {
  height: 370px;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}
</style>
