/**
 * Documentation: This configures the amplify sdk for the browser.
 */
import Vue from "vue";
import Amplify, * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin, components } from "aws-amplify-vue";
import awsconfig from "../aws-exports";

const Auth = AmplifyModules.Auth;

// NOTE: This determines which redirect url to use localhost or the server
const urlsIn = awsconfig.oauth.redirectSignIn.split(",");
const urlsOut = awsconfig.oauth.redirectSignOut.split(",");
const oauth = {
  domain: awsconfig.oauth.domain,
  scope: awsconfig.oauth.scope,
  redirectSignIn: awsconfig.oauth.redirectSignIn,
  redirectSignOut: awsconfig.oauth.redirectSignOut,
  responseType: awsconfig.oauth.responseType,
};
const hasLocalhost = hostname =>
  Boolean(
    hostname.match(/localhost/) ||
      hostname.match(/127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/)
  );
const hasHostname = hostname =>
  Boolean(hostname.includes(window.location.hostname));
const isLocalhost = hasLocalhost(window.location.hostname);
if (isLocalhost) {
  urlsIn.forEach(e => {
    if (hasLocalhost(e)) {
      oauth.redirectSignIn = e;
    }
  });
  urlsOut.forEach(e => {
    if (hasLocalhost(e)) {
      oauth.redirectSignOut = e;
    }
  });
} else {
  urlsIn.forEach(e => {
    if (hasHostname(e)) {
      oauth.redirectSignIn = e;
    }
  });
  urlsOut.forEach(e => {
    if (hasHostname(e)) {
      oauth.redirectSignOut = e;
    }
  });
}
const configUpdate = awsconfig;
configUpdate.oauth = oauth;
Amplify.configure(configUpdate);

// NOTE: Make Amplify and Auth globally available.
window.Amplify = Amplify;
window.Auth = Auth;

// NOTE: Make the amplify vue components available in every component. This is
// made necessary by ssr.
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.component(components);
