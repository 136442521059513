export const state = () => ({
  authenticatedUser: null,
  forgotPassword: false,
  "cookie:accepted": false,
});

export const mutations = {
  setUser(state, value) {
    state.authenticatedUser = value;
  },
  updateAuthenticatedUser(state, authenticatedUser) {
    state.authenticatedUser = authenticatedUser;
  },
  setForgotPassword(state, value) {
    state.forgotPassword = value;
  },
  setCookie(state, value) {
    state["cookie:accepted"] = value;
  },
};

export const getters = {
  email(state) {
    if (state?.authenticatedUser?.attributes?.email) {
      return state?.authenticatedUser?.attributes?.email;
    }
    const attrs = state?.authenticatedUser?.UserAttributes;
    if (attrs?.length) {
      const emailAttr = attrs.find(a => a.Name === "email");
      if (emailAttr) {
        return emailAttr.Value;
      }
    }
  },
};

export const actions = {
  clear({ commit, state }) {
    commit("setUser", null);
  },
};
