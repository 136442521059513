import consola from "consola";

export default async function ({
  route,
  store,
  $axios,
  $cookies,
  redirect,
  params,
}) {
  consola.info("middleware: survey");
  consola.info(`middleware survey path: ${route.path}`);

  const resetRoutes = [
    "/",
    "/covid-19",
    "/login",
    "/user-agreement",
    "/privacy",
    "/scenario",
    "/signup",
    "/tactics",
  ];
  if (resetRoutes.includes(route.path)) {
    $cookies.remove("showCustomized");
  }
  const username = $cookies.get("cognito-username");
  if (username) {
    if (
      route.path.includes("/survey") &&
      params?.survey !== "retrospective" &&
      !route.path.match(/\/debug$/)
    ) {
      consola.error("should redirect here");
      return redirect("/");
    }

    try {
      const { data: status } = await $axios.get(
        `/api/survey/${route?.params?.survey}/status/${username}`
      );
      if (status?.completedAt) {
        // This is what was throwing off the routing in pages/survey/_survey.vue
        consola.info("middleware: survey redirect");
        return redirect("/results");
      }
      const { data: result } = await $axios.get(
        `/api/survey/hasSurvey/retrospective/${username}`
      );
      store.commit("survey/setHasSurvey", result.hasSurvey);
    } catch (error) {
      console.error(error.message);
    }
  }
}
