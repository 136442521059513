import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b6415430 = () => interopDefault(import('../pages/covid-19.vue' /* webpackChunkName: "pages/covid-19" */))
const _3e4925f2 = () => interopDefault(import('../pages/damage.vue' /* webpackChunkName: "pages/damage" */))
const _65514018 = () => interopDefault(import('../pages/disruptions.vue' /* webpackChunkName: "pages/disruptions" */))
const _220f2ec6 = () => interopDefault(import('../pages/info.vue' /* webpackChunkName: "pages/info" */))
const _72937701 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _65c323a0 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _25a155e4 = () => interopDefault(import('../pages/results.vue' /* webpackChunkName: "pages/results" */))
const _3ea9bca8 = () => interopDefault(import('../pages/scenario.vue' /* webpackChunkName: "pages/scenario" */))
const _2c355630 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _4414765a = () => interopDefault(import('../pages/tactics.vue' /* webpackChunkName: "pages/tactics" */))
const _80c368c0 = () => interopDefault(import('../pages/user-agreement.vue' /* webpackChunkName: "pages/user-agreement" */))
const _0b7e0976 = () => interopDefault(import('../pages/survey/debug.vue' /* webpackChunkName: "pages/survey/debug" */))
const _4efd8448 = () => interopDefault(import('../pages/survey/_survey.vue' /* webpackChunkName: "pages/survey/_survey" */))
const _ae28582c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/covid-19",
    component: _b6415430,
    name: "covid-19"
  }, {
    path: "/damage",
    component: _3e4925f2,
    name: "damage"
  }, {
    path: "/disruptions",
    component: _65514018,
    name: "disruptions"
  }, {
    path: "/info",
    component: _220f2ec6,
    name: "info"
  }, {
    path: "/login",
    component: _72937701,
    name: "login"
  }, {
    path: "/privacy",
    component: _65c323a0,
    name: "privacy"
  }, {
    path: "/results",
    component: _25a155e4,
    name: "results"
  }, {
    path: "/scenario",
    component: _3ea9bca8,
    name: "scenario"
  }, {
    path: "/signup",
    component: _2c355630,
    name: "signup"
  }, {
    path: "/tactics",
    component: _4414765a,
    name: "tactics"
  }, {
    path: "/user-agreement",
    component: _80c368c0,
    name: "user-agreement"
  }, {
    path: "/survey/debug",
    component: _0b7e0976,
    name: "survey-debug"
  }, {
    path: "/survey/:survey?",
    component: _4efd8448,
    name: "survey-survey"
  }, {
    path: "/",
    component: _ae28582c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
