import { Auth } from "aws-amplify";
import consola from "consola";

const COGNITO_URL = "https://cognito-idp.us-east-1.amazonaws.com/";

export default async function ({ route, redirect, store, $axios, $cookies }) {
  consola.info("middleware: auth");
  consola.info(`middleware auth path: ${route.path}`);
  const unguardedRoutes = [
    "/",
    "/covid-19",
    "/login",
    "/user-agreement",
    "/demo",
  ];
  if (unguardedRoutes.includes(route.path)) {
    return;
  }

  const token = $cookies.get("brc-token");
  if (token) {
    try {
      // consola.debug({ token });
      const { data: user } = await $axios.post(
        COGNITO_URL,
        { AccessToken: token },
        {
          headers: {
            "Content-Type": "application/x-amz-json-1.1",
            "X-Amz-Target": "AWSCognitoIdentityProviderService.GetUser",
          },
        }
      );
      if (user) {
        consola.info("middleware auth found user");
        store.commit("user/updateAuthenticatedUser", user);
      } else {
        consola.info(
          "middleware auth currentAuthenticatedUser didn't find user"
        );
        $cookies.remove("cognito-username");
        $cookies.remove("brc-token");
        store.commit("user/updateAuthenticatedUser", null);
      }
      return;
    } catch (error) {
      consola.debug(error.error);
      $cookies.remove("cognito-username");
      $cookies.remove("brc-token");
      store.dispatch("user/clear");
      store.dispatch("clear");
      console.log("middleware auth redirect 6");
      return redirect("/login");
    }
  } else {
    return redirect("/login");
  }

  // try {
  //   // const user = await Auth.currentAuthenticatedUser();
  //   if (user) {
  //     consola.info("auth middleware found user");
  //     store.commit("user/updateAuthenticatedUser", user);
  //   } else {
  //     consola.info("currentAuthenticatedUser didn't find user");
  //     store.commit("user/updateAuthenticatedUser", null);
  //   }
  //   return;
  // } catch (error) {
  //   consola.debug(error.error);
  //   store.commit("user/updateAuthenticatedUser", null);
  //   return redirect("/login");
  // }
}
