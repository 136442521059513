import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faSquare as freeFarFaSquare
} from '@fortawesome/free-regular-svg-icons'

import {
  faAngleUp as freeFasFaAngleUp,
  faAngleDown as freeFasFaAngleDown,
  faBars as freeFasFaBars,
  faSpinner as freeFasFaSpinner,
  faStar as freeFasFaStar,
  faCaretUp as freeFasFaCaretUp,
  faCaretDown as freeFasFaCaretDown,
  faCheckSquare as freeFasFaCheckSquare,
  faInfoCircle as freeFasFaInfoCircle
} from '@fortawesome/free-solid-svg-icons'

import {
  faFacebook as freeFabFaFacebook,
  faGoogle as freeFabFaGoogle
} from '@fortawesome/free-brands-svg-icons'

library.add(
  freeFarFaSquare,
  freeFasFaAngleUp,
  freeFasFaAngleDown,
  freeFasFaBars,
  freeFasFaSpinner,
  freeFasFaStar,
  freeFasFaCaretUp,
  freeFasFaCaretDown,
  freeFasFaCheckSquare,
  freeFasFaInfoCircle,
  freeFabFaFacebook,
  freeFabFaGoogle
)

config.autoAddCss = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)
