<template>
  <transition name="transition">
    <header v-if="welcome" key="header--welcome" class="header header--welcome">
      <h1 class="header__title">Welcome</h1>
    </header>
    <header v-else key="header" class="header">
      <router-link class="header__button" to="/" als="return home">
        <img
          class="header__image"
          alt="brc header logo"
          src="../../assets/images/BRC-header-logo.svg"
        />
      </router-link>
      <a class="header__button" href="/" @click.prevent="setShowDomainInfo">
        <font-awesome-icon icon="bars" />
      </a>
    </header>
  </transition>
</template>

<script>
import "./header.css";

export default {
  name: "Header",
  props: {
    welcome: {
      type: Boolean,
    },
  },
  methods: {
    setShowDomainInfo() {
      this.$store.commit("toggleShowDomainInfo");
    },
  },
};
</script>
